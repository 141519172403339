import {Component, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms'
import {SearchUserService} from '../../administration/services/search-users/search-user.service';
import {UserGroupsService} from '../../user-groups/services/user-groups.service';
import {UserIdService} from '../../core/adfs/services/user-id.service';
import {ViewMyInfoConstants as C} from './view-my-info.constants';
import {
	CodeDescription,
	UserAuditDetails,
	UserDetails,
	UserGroupResponse
} from './user-info.model';
import {UtilityCommon} from "../../core/utility.common";
import {CommonConstants} from "../../common/common.constants";
import {EnvConstants} from "../../common/env.constants";

@Component({
	selector: 'view-my-info',
	templateUrl: './view-my-info.component.html',
	styleUrls: ['./view-my-info.component.scss']
})
export class ViewMyInfoComponent implements OnInit {
	userInfoForm: FormGroup;
	itemsPerPage: number;
	primaryInfo: any;
	infoLoader: boolean=false;
	constructor(
		private fb: FormBuilder,
		private searchUserService: SearchUserService,
		private userGroupsService: UserGroupsService,
		private userIdService: UserIdService
	) {
		this.userInfoForm = this.fb.group({
			mailHost: new FormControl(),
			emailType: new FormControl([]),
			[C.USER_ID]: [C.EMPTY_STRING],
			[C.FIRST_NAME]: [C.EMPTY_STRING],
			[C.LAST_NAME]: [C.EMPTY_STRING],
			[C.EMAIL]: [C.EMPTY_STRING],
			[C.PHONE]: [C.EMPTY_STRING],
			[C.FAX]: [C.EMPTY_STRING],
			[C.PRIMARY_LOCATION]: [C.EMPTY_STRING],
			[C.CITY]: [C.EMPTY_STRING],
			[C.STATE]: [C.EMPTY_STRING],
			[C.COUNTRY]: [C.EMPTY_STRING],
			[C.POSTAL_CODE]: [C.EMPTY_STRING],
			[C.JOB_ROLE]: [C.EMPTY_STRING],
			[C.ADDRESS]: [C.EMPTY_STRING],
			[C.STATUS]: [C.EMPTY_STRING],
			[C.PREFERRED_LANGUAGE]: [C.EMPTY_STRING],
			[C.SITE_CODE_TYPE]: [C.EMPTY_STRING],
			[C.LAST_PASSWORD_RESET]: [C.EMPTY_STRING],
			[C.PASSWORD_EXPIRATION_DATE]: [C.EMPTY_STRING],
			[C.EMAIL_TYPE]: [C.EMPTY_STRING],
			[C.DEALER]: this.fb.group({
				[C.CODES]: [[]],
				[C.CODE_DESC]: [[]],
				[C.PRIMARY_CODE]: [C.EMPTY_STRING],
				[C.PRIMARY_DESCRIPTION]: [C.EMPTY_STRING]
			}),
			[C.LAST_AUDITED_USER]: [C.EMPTY_STRING],
			[C.LAST_AUDITED_DATE]: [C.EMPTY_STRING]
		});
	}

	ngOnInit(): void {
		this.userIdService.getUserId().subscribe((userId) => {
			if (userId) {
				this.infoLoader=true;
				this.fetchUserDetails(userId);
				this.fetchUserGroups(userId);
				this.fetchUserAuditDetails(userId);
			} else {
				console.log('User ID not found');
			}
		});
	}

	fetchUserDetails(userId: string): void {
		this.searchUserService.searchUsers(userId).subscribe({
			next: (response: UserDetails) => {
				this.userInfoForm.patchValue({
					[C.USER_ID]: response.userId || C.HYPHEN,
					[C.FIRST_NAME]: response.givenName || C.HYPHEN,
					[C.LAST_NAME]: response.sn || C.HYPHEN,
					[C.EMAIL]: response.mail || C.HYPHEN,
					[C.PHONE]: response.telephoneNumber || C.HYPHEN,
					[C.FAX]: response.facsimileTelephoneNumber || C.HYPHEN,
					[C.PRIMARY_LOCATION]: response.fordSiteCode || C.HYPHEN,
					[C.CITY]: response.city || C.HYPHEN,
					[C.STATE]: response.state || C.HYPHEN,
					[C.COUNTRY]: response.country || C.HYPHEN,
					[C.POSTAL_CODE]: response.postalCode || C.HYPHEN,
					[C.JOB_ROLE]: response.fordJobRole?.split(CommonConstants.SEMICOLONSEPARATOR) || C.HYPHEN,
					[C.ADDRESS]: response.postalAddress || C.HYPHEN,
					[C.STATUS]: response.fordStatus || C.HYPHEN,
					[C.PREFERRED_LANGUAGE]: response.preferredLanguage || C.HYPHEN,
					[C.SITE_CODE_TYPE]: response.fordSiteCodeType || C.HYPHEN,
					[C.LAST_PASSWORD_RESET]: UtilityCommon.formatDate(response.pwdLastSet) || C.HYPHEN,
					[C.PASSWORD_EXPIRATION_DATE]: UtilityCommon.calculatePasswordExpirationDate(response.pwdLastSet) || C.HYPHEN,
					[C.EMAIL_TYPE]: response.emailType || C.HYPHEN
				});
				this.updateMailHost();
				this.infoLoader=false;
			},
			error: (error: any) => {
				console.error('Error fetching user details', error);
				this.infoLoader=false;
			}
		});
	}
	updateMailHost() {
		const emailType = this.userInfoForm.get('emailType')?.value;
		const mailHostControl = this.userInfoForm.get(
			'mailHost'
		) as FormControl;
		mailHostControl.setValue(
			emailType && emailType.length > 0 && emailType !== '-'
		);
	}

	fetchUserGroups(userId: string): void {
		this.searchUserService.getGroupsForUser(userId).subscribe({
			next: (data: UserGroupResponse) => {
				const primaryCode = this.extractCodeFromDn(data.primaryInfo.dn);
				const primaryDescription =
					data.primaryInfo.description || C.HYPHEN;

				const codes: string[] = [];
				const code_desc: string[] = [];

				data.groupDetails.forEach((group) => {
					const code = group.cn.split(C.HYPHEN).pop();
					codes.push(code);
					code_desc.push(group.description || C.HYPHEN);
				});

				this.userInfoForm.patchValue({
					[C.DEALER]: {
						[C.CODES]: codes,
						[C.CODE_DESC]: code_desc,
						[C.PRIMARY_CODE]: primaryCode,
						[C.PRIMARY_DESCRIPTION]: primaryDescription
					}
				});

				this.itemsPerPage = codes.length;
			},
			error: (error: any) => {
				console.error('Error fetching user groups', error);
			}
		});
	}

	fetchUserAuditDetails(userId: string): void {
		this.userGroupsService.getUserAuditDetails(userId).subscribe({
			next: (response: UserAuditDetails) => {
				this.userInfoForm.patchValue({
					[C.LAST_AUDITED_USER]: response.lastAudited,
					[C.LAST_AUDITED_DATE]: response.lastAuditDate?.split('T')[0]
				});
			},
			error: (error: any) => {
				console.error('Error fetching user audit details', error);
			}
		});
	}

	isPrimaryLocation(code: string): boolean {
		return code === this.userInfoForm.value[C.DEALER]?.[C.PRIMARY_CODE];
	}

	getPaginatedCodes(): CodeDescription[] {
		const primaryCode = this.userInfoForm.value[C.DEALER]?.[C.PRIMARY_CODE];
		const primaryDescription =
			this.userInfoForm.value[C.DEALER]?.[C.PRIMARY_DESCRIPTION];
		const codes =
			this.userInfoForm.value[C.DEALER]?.[C.CODES].filter(
				(code: string) => code !== primaryCode
			) || [];
		return [
			{
				code: primaryCode,
				description: primaryDescription
			},
			...codes.map((code: string) => ({
				code,
				description:
					this.userInfoForm.value[C.DEALER]?.[C.CODE_DESC][
						this.userInfoForm.value[C.DEALER]?.[C.CODES].indexOf(
							code
						)
					]
			}))
		];
	}

	shouldPaginate(): boolean {
		return this.userInfoForm.value[C.DEALER]?.[C.CODES].length >= 5;
	}

	getStatusText(): string {
		return this.userInfoForm.value[C.STATUS] === C.STATUS_ON
			? C.STATUS_ACTIVE
			: C.STATUS_INACTIVE;
	}

	isGroupsEmpty(): boolean {
		const dealer = this.userInfoForm.value[C.DEALER];
		const codes = dealer?.[C.CODES];
		const primaryCode = dealer?.[C.PRIMARY_CODE];
		return !(codes?.length || primaryCode);
	}

	isAuditDetailsEmpty(): boolean {
		return (
			!this.userInfoForm.get(C.LAST_AUDITED_USER)?.value ||
			!this.userInfoForm.get(C.LAST_AUDITED_DATE)?.value
		);
	}

	private extractCodeFromDn(dn: string): string {
		return dn.split('=')[1].split(',')[0];
	}

	protected readonly EnvConstants = EnvConstants;
}
