import {FormGroup, NgForm} from "@angular/forms";
import {TreeNode} from "primeng/api";

export class UtilityCommon {
	static parseDate(dateString: string | null): Date | null {
		return dateString ? new Date(dateString) : null;
	}

	static parseDatetoUTC(dateString: Date): Date {
			return new Date(Date.UTC(dateString.getFullYear(), dateString.getMonth(), dateString.getDate(), dateString.getHours(), dateString.getMinutes()
				, dateString.getSeconds()));
	}

	static isObjectEmpty(obj: any): boolean {
		return Object.keys(obj).length === 0;
	}

	static markFormControlsAsDirty(form: NgForm): void {
		Object.keys(form.controls).forEach(field => {
			const control = form.controls[field];
			control.markAsDirty({ onlySelf: true });
		});
	}

	static markFormGroupAsDirty(formGroup: FormGroup): void {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			if (control instanceof FormGroup) {
				this.markFormGroupAsDirty(control); // Recursively mark nested FormGroups
			} else {
				control.markAsDirty({ onlySelf: true });
			}

		});
	}

	static getDateDiff(inputDate: string | Date) {
		if (typeof inputDate === 'string') {
			inputDate = new Date(inputDate);
		}
		const currDate = new Date();
		let days = Math.floor(
			(Date.UTC(currDate.getFullYear(), currDate.getMonth(), currDate.getDate()) -
				Date.UTC(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate())) /
			(1000 * 60 * 60 * 24)
		);
		return days;
	}
	static isValidDateAudit(dateString: string): boolean {
		const date = new Date(dateString);
		return !isNaN(date.getTime());
	}
	static isValidDate(dateString: string, format: string = 'DD/MM/YYYY'): boolean {
		const dateRegex = /^(?<day>\d{2})\/(?<month>\d{2})\/(?<year>\d{4})$/; // DD/MM/YYYY format

		const match = dateString?.match(dateRegex);

		if (!match) {
			console.warn(`Invalid date format: ${dateString}. Expected format: DD/MM/YYYY`);
			return false;
		}

		const { day, month, year } = match.groups!;

		const parsedDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));

		if (parsedDate.getFullYear() !== parseInt(year) ||
				parsedDate.getMonth() !== parseInt(month) - 1 ||
				parsedDate.getDate() !== parseInt(day)) {
			console.warn(`Invalid date values: ${dateString}`);
			return false;
		}

		return !isNaN(parsedDate.getTime());
	}

	static formatDate(pwdLastSet: string): string {
		if (!pwdLastSet) {
			return '-';
		}

		const pwdSetTime = parseInt(pwdLastSet, 10);
		if (isNaN(pwdSetTime) || pwdSetTime <= 0) {
			return '-';
		}

		const fileTimeEpoch = 0x19db1ded53e8000;
		const javaTime = (pwdSetTime - fileTimeEpoch) / 10000;
		const date = new Date(javaTime);

		const options: Intl.DateTimeFormatOptions = {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			second: 'numeric'
		};

		return date.toLocaleDateString('en-US', options);
	}

	static calculatePasswordExpirationDate(pwdLastSet: string): string {
		if (!pwdLastSet) {
			return '-';
		}

		const pwdSetTime = parseInt(pwdLastSet, 10);
		if (isNaN(pwdSetTime) || pwdSetTime <= 0) {
			return '-';
		}

		const fileTimeEpoch = 0x19db1ded53e8000;
		const javaTime = (pwdSetTime - fileTimeEpoch) / 10000;
		const date = new Date(javaTime);

		const expirationDate = new Date(date);
		expirationDate.setDate(expirationDate.getDate() + 371);

		const options: Intl.DateTimeFormatOptions = {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			second: 'numeric'
		};

		return expirationDate.toLocaleDateString('en-US', options);
	}

	static formatTempDate(pwdLastSet: string): string {
		if (!pwdLastSet) {
			return '-';
		}

		const pwdSetTime = parseInt(pwdLastSet, 10);
		if (isNaN(pwdSetTime) || pwdSetTime <= 0) {
			return '-';
		}

		const fileTimeEpoch = 0x19db1ded53e8000;
		const javaTime = (pwdSetTime - fileTimeEpoch) / 10000;
		const date = new Date(javaTime);
		const options: Intl.DateTimeFormatOptions = {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
		};

		return date.toLocaleDateString('en-US', options);
	}

	static formatPwdLastSet(dateString):string {
		const [monthStr, dayStr, yearStr] = dateString.split(' ');
		const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		const month = months.indexOf(monthStr);
		const day = parseInt(dayStr.replace(',', '')); //remove comma
		const year = parseInt(yearStr);

		const date = new Date(year, month, day);
		const formattedDate = date.toLocaleDateString('en-GB', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
		});
		return formattedDate;
	}

	static reorderSelectedElement(dropdownList:any[],selectedElement:any){
		const firstElement = dropdownList.find(s => selectedElement == s);
		if(firstElement){
			dropdownList = dropdownList.filter(element=> element !== firstElement);
			dropdownList.unshift(firstElement);
			return dropdownList;
		}
		return dropdownList;
	}

	static getLastNode(node: TreeNode) {
		if(!node){
			return null;
		}
		if(node?.children?.length == 0 || node.leaf){
			return node;
		}
		return this.getLastNode(node.children[0]);
	}

	static createNestedNode(nodes: TreeNode[]): TreeNode {
		if (nodes.length === 0) {
			return null;
		}
		let currentNode: TreeNode = nodes[0];
		for (let i = 1; i < nodes.length; i++) {
			if (!currentNode.children) {
				currentNode.children = [];
			}
			currentNode.children.push(nodes[i]);
			if (nodes[i].leaf) {
				break;
			}
			currentNode = nodes[i];
		}
		return nodes[0];
	}

	static mapResponseToNode(label, child = true) {
		let node: TreeNode = {
			label: label,
			data: label,
			expanded: true,
			children:[]
		};
		if (child) {
			let leaf = true;
			delete node.children;
			return {...node, leaf};
		} else {
			return node;
		}
	}

	static getNodeLevel(node: TreeNode): number {
		let level = 1;
		let parent = node.parent;
		while (parent) {
			level++;
			parent = parent.parent;
		}
		return level;
	}
}
