import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AdministrationService } from '../../services/administration.service';
import { ManageTempIdsComponent } from '../manage-temp-ids/manage-temp-ids.component';
import { ManageTemporaryIdService } from '../../services/manage.temporary.id.service';
import { Subject, takeUntil } from 'rxjs';
import { CommonConstants } from 'src/app/common/common.constants';
import {UserIdService} from "../../../core/adfs/services/user-id.service";

interface JobRole {
  name: string
}


@Component({
  selector: 'edit-temp-id',
  templateUrl: './edit-temp-id.component.html',
  styleUrls: ['./edit-temp-id.component.scss']
})
export class EditTempIdComponent {

  loading: boolean = false;
  editTempForm:FormGroup;
  visible:boolean = false;
  private onDestroy$ = new Subject<void>();
  editableRecord: any;
  jobrole!: JobRole[];
  @Input() selectedTempGroup: string;
  @Input() jobRoleList: any;
  enableDialog: boolean = false;
  responseMessage: string = '';
  loggedinUser: string = '';
  private unsubscribe = new Subject<void>();
  constructor(private manageTempidService: ManageTemporaryIdService,private fb: FormBuilder,private authService:UserIdService) {
    this.editTempForm = this.fb.group({
      userId: new FormControl('', Validators.required),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required,Validators.email,Validators.maxLength(256),Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]),
      primaryLocation: new FormControl('', Validators.required),
      jobrole: new FormControl(''),
      comments: new FormControl('')
    });

    this.manageTempidService.getOpenEditTemporaryId().pipe(takeUntil(this.onDestroy$)).subscribe(data => {
      this.visible=data;
    });

    this.manageTempidService.getEditTempData().pipe(takeUntil(this.onDestroy$)).subscribe(data => {
      this.editableRecord = data;
      const jobRole = this.jobRoleList?.find(
        (role) => role.jobRoleName === this.editableRecord?.jobrole
      );
    this.editTempForm.patchValue({
      userId: { value: this.editableRecord.userId},
      firstName: { value: this.editableRecord.firstName },
      lastName: { value: this.editableRecord.lastName },
      email: this.editableRecord.email,
      primaryLocation: { value: this.editableRecord.primaryLocation },
      jobrole: jobRole,
      comments: this.editableRecord.comment
    });
    this.disableAndPatch('userId', this.editableRecord.userId);
    this.disableAndPatch('firstName', this.editableRecord.firstName);
    this.disableAndPatch('lastName', this.editableRecord.lastName);
    this.disableAndPatch('primaryLocation', this.editableRecord.primaryLocation);
    });

	}


  ngOnInit() {
    this.authService.getUserId().pipe(takeUntil(this.unsubscribe)).subscribe((data:string) => {
      this.loggedinUser = data;
    });

  }
  disableAndPatch(controlName: string, value: any) {
    const control = this.editTempForm.get(controlName);
    if (control) {
      control.enable();
      this.editTempForm.patchValue({ [controlName]: value });
      control.disable();
    }
  }
  onSubmit(){
    this.loading=true;
    this.enableDialog=true;
    let request={
      userId: this.editableRecord.userId,
      email: this.editTempForm.value.email,
      fordJobRoles: this.editTempForm.value?.jobrole?.jobRoleName ? this.editTempForm.value?.jobrole?.jobRoleName : CommonConstants.NULL,
      comment: this.editTempForm.value.comments ? this.editTempForm.value.comments : CommonConstants.NULL,
      lastModifiedBy: this.loggedinUser,
    }

    this.manageTempidService.updateTempIdUserService(request).subscribe({
      next: data => {
        this.loading=false;
        this.visible=false;
        this.responseMessage=data.message;
        this.manageTempidService.openEditTemporaryId(this.visible);
        this.editTempForm.reset();
      },
      error: error => {
        this.responseMessage=error?.message;
        this.loading = false;
      }
    });
  }

  closeDialog(){
    this.visible = false;
    this.manageTempidService.openEditTemporaryId(this.visible);
  }
  disableandRefresh(){
    this.enableDialog=false;
    this.manageTempidService.updateTempTable(true);
  }
  ngOnDestroy(){
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
