<app-title></app-title>
<div class="view-my-info">
	<div *ngIf="infoLoader" class="card flex justify-content-center">
		<p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
</div>
	<div  *ngIf="!infoLoader" class="user-summary">
		<div class="info-item">
			<label for="userId">{{"label.userID" | translate}}:</label>
			<span id="userId">{{ userInfoForm.get("userId")?.value }}</span>
		</div>
		<div class="info-item">
			<label for="firstName">{{"label.firstName" | translate}}:</label>
			<span id="firstName">{{ userInfoForm.get('firstName')?.value }}</span>
		</div>
		<div class="info-item">
			<label for="lastName">{{"label.lastName" | translate}}:</label>
			<span id="lastName">{{ userInfoForm.get("lastName")?.value }}</span>
		</div>
	</div>
	<p-tabView *ngIf="!infoLoader" >
		<p-tabPanel header="{{'header.profile' | translate}}">
			<div class="content-container">
				<div class="content-left">
					<div class="profile-info">
						<div class="info-item">
							<label for="email">{{"label.email" | translate}}:</label>
							<span id="email">{{ userInfoForm.get('email')?.value }}</span>
						</div>
						<div class="info-item">
							<label for="phone">{{"label.phone" | translate}}:</label>
							<span id="phone">{{ userInfoForm.get('phone')?.value }}</span>
						</div>
						<div class="info-item">
							<label for="fax">{{"label.fax" | translate}}:</label>
							<span id="fax">{{ userInfoForm.get('fax')?.value }}</span>
						</div>
						<div class="info-item">
							<label for="preferredLanguage">{{"label.preferredLanguage" | translate}}:</label>
							<span id="preferredLanguage">{{ userInfoForm.get('preferredLanguage')?.value }}</span>
						</div>
						<div class="info-item">
							<label for="primaryLocation">{{"label.primaryLocation" | translate}}:</label>
							<span id="primaryLocation">{{ userInfoForm.get('primaryLocation')?.value }}</span>
						</div>
						<div class="info-item">
							<label for="city">{{"label.city" | translate}}:</label>
							<span id="city">{{ userInfoForm.get('city')?.value }}</span>
						</div>
						<div class="info-item">
							<label for="state">{{"label.state" | translate}}:</label>
							<span id="state">{{ userInfoForm.get('state')?.value }}</span>
						</div>
						<div class="info-item">
							<label for="country">{{"label.country" | translate}}:</label>
							<span id="country">{{ userInfoForm.get('country')?.value }}</span>
						</div>
						<div class="info-item">
							<label for="postalCode">{{"label.postalCode" | translate}}:</label>
							<span id="postalCode">{{ userInfoForm.get('postalCode')?.value }}</span>
						</div>
					</div>
				</div>
				<div class="vertical-line"></div>
				<div class="content-right">
					<div class="profile-info">
						<div class="info-item">
							<label for="address">{{"label.address" | translate}}:</label>
							<span id="address">{{ userInfoForm.get('address')?.value }}</span>
						</div>
						<div class="info-item">
							<label for="jobRole">{{"label.jobRole" | translate}}:</label>
							<span id="jobRole">{{ userInfoForm.get('jobRole')?.value }}</span>
						</div>
						<div class="info-item">
							<label for="status">{{"label.status" | translate}}:</label>
							<span id="status">{{ getStatusText() }}</span>
						</div>
						<div class="info-item">
							<label for="siteCodeType">{{"label.siteCodeType" | translate}}:</label>
							<span id="siteCodeType">{{ userInfoForm.get('siteCodeType')?.value }}</span>
						</div>
						<div class="info-item">
							<label for="lastPasswordReset">{{"label.lastPasswordReset" | translate}}:</label>
							<span id="lastPasswordReset">{{ userInfoForm.get('lastPasswordReset')?.value }}</span>
						</div>
						<div class="info-item">
							<label for="passwordExpirationDate">{{"label.passwordExpirationDate" | translate}}:</label>
							<span id="passwordExpirationDate">{{ userInfoForm.get('passwordExpirationDate')?.value }}</span>
						</div>
					</div>
				</div>
			</div>
		</p-tabPanel>
		<p-tabPanel header="{{'header.groups' | translate}}" class="group-tab" [disabled]="isGroupsEmpty()">
			<div class="group-info">
				<p-table [value]="getPaginatedCodes()" [paginator]="shouldPaginate()" [rows]="itemsPerPage" [rowsPerPageOptions]="EnvConstants.PAGINATION_ROW">
					<ng-template pTemplate="header">
						<tr>
							<th style="width: 50%;">{{"label.code" | translate}}</th>
							<th style="width: 50%;">{{"label.description" | translate}}</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-item>
						<tr>
							<td>{{ item.code }} <i *ngIf="isPrimaryLocation(item.code)" class="fa fa-home" aria-hidden="true"></i></td>
							<td class="code-description">{{ item.description }}</td>
						</tr>
					</ng-template>
				</p-table>
				<div class="primary-location-indicator">
					<i class="fa fa-home"></i> {{"label.primaryLocation" | translate}}
				</div>
			</div>
		</p-tabPanel>
		<p-tabPanel header="{{'label.audit' | translate}}" [disabled]="isAuditDetailsEmpty()">
			<div class="audit-info">
				<div class="info-item">
					<label>{{"label.userID" | translate}}:</label>
					<span>{{ userInfoForm.get('userId')?.value }}</span>
				</div>
				<div class="info-item">
					<label>{{"label.lastAuditedUser" | translate}}:</label>
					<span>{{ userInfoForm.get('lastAuditedUser')?.value }}</span>
				</div>
				<div class="info-item">
					<label>{{"label.lastAuditedDate" | translate}}:</label>
					<span>{{ userInfoForm.get('lastAuditedDate')?.value }}</span>
				</div>
			</div>
		</p-tabPanel>
		<p-tabPanel header="{{'header.emailPreferences' | translate}}">
			<div class="email-preference col-12 md:col-10 lg:col-8 mx-auto">
				<div class="flex flex-wrap gap-4 mb-4 mt-1 justify-content-center">
					<div class="grid align-items-center m-0 shadow-1 bg-color-7 info-card font-bold p-4 gap-3 col-12 md:col-5">
						<label class="text-color-1">{{"label.userID" | translate}} :</label>
						<div>
							<span>{{ userInfoForm.get("userId")?.value }}</span>
						</div>
					</div>
					<div class="grid align-items-center m-0 shadow-1 bg-color-7 info-card font-bold p-4 gap-3 col-12 md:col-5">
						<label class="text-color-1">{{"label.primaryEmail" | translate}}:</label>
						<div>
							<span>{{ userInfoForm.get("email")?.value || " - " }}</span>
						</div>
					</div>
				</div>
				<p-fieldset legend="{{'header.emailPreferences' | translate}}">
					<div class="flex flex-wrap justify-content-center" [formGroup]="userInfoForm">
						<div class="col-12 md:col-4 flex flex-column">
							<label class="text-color-1 font-bold mb-2">{{"label.emailSubscription" | translate}}:</label>
							<div>
								<p-inputSwitch formControlName="mailHost"></p-inputSwitch>
							</div>
						</div>
						<div class="col-12 md:col-8 flex flex-column">
							<label class="text-color-1 font-bold mb-2">{{"label.emailType" | translate}}:</label>
							<div>
								<div *ngIf="userInfoForm.get('emailType').value" class="w-full grid gap-1 col-12 mt-1 pt-0">
									<ng-container *ngFor="let emailtype of userInfoForm.get('emailType').value">
										<p-chip [label]="emailtype"></p-chip>
									</ng-container>
								</div>
							</div>
						</div>
					</div>
				</p-fieldset>
			</div>
		</p-tabPanel>
	</p-tabView>
</div>
