export const AppRoutes = {
	MANAGE_USER_GROUPS: 'manage-user-groups',
	VIEW_MY_INFO: 'view-my-info',
	MANAGE_JOB_ROLES: 'manage-job-roles',
	MANAGE_AUDIT_CONFIGURATION: 'manage-audit-configuration',
	MANAGE_TEMPORARY_IDS: 'manage-temporary-ids',
	SEARCH_USERS: 'search-users',
	CREATE_TEMPORARY_GROUP: 'create-temporary-group',
	SITE_RECOVERY_TOOL: 'site-recovery-tool',
	REPORT: 'report',
	OAUTH: 'oauth',
	ADMIN_REPORT: 'admin-report',
	USER_REPORT: 'user-report',
	AUDIT_REPORT: 'audit-report',
	NOT_FOUND: '404',
	UNAUTHORIZED: '401',
	WILDCARD: '**',
	CHANGE_MY_PROFILE: 'change-my-profile',
	SPS_USER_MANUALS: 'sps-users-manuals',
	HELP_IN_OTHER_LANG: 'help-in-other-languages'
};
