import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {finalize, map, Subject, takeUntil} from "rxjs";
import {MenubarService} from "../../common/menubar/service/menubar.service";
import {AdministrationService} from "../services/administration.service";
import {AuditService} from "../services/audit.service";
import {
	AuditExtendRequest,
	AuditRequest,
	AuditResponse,
	BaseModelRequest,
	ConditionEnum
} from "./audit-extend-request.model";
import {PasswordStatus} from "../../user-groups/members-table/member-user.model";
import {UtilityCommon} from "../../core/utility.common";
import {HttpStatusCode} from "@angular/common/http";
import {AbstractControl, FormBuilder, FormGroup, NgForm, Validators} from "@angular/forms";
import {MessageService} from "primeng/api";
import {auditIntervalValidator, notificationValidator} from "../../core/validator";
import {CommonConstants} from "../../common/common.constants";
import {PageType} from "../../common/models/user-role/page-type.enum";
import {UserGroupsService} from "../../user-groups/services/user-groups.service";
import {BranchModel} from "../../common/models/branch.model";

@Component({
  selector: 'manage-audit-configuration',
  templateUrl: './manage-audit-configuration.component.html',
  styleUrls: ['./manage-audit-configuration.component.scss']
})
export class ManageAuditConfigurationComponent implements OnInit{
	isCollapsed:boolean;
	private destroy$ = new Subject<void>();
	selectedBranch: any;
	selectedSiteCodeType: any;
	selectedMarket: any;
	showExtendAudit:boolean = false;
	siteCodeTypeList: any[] = []
	showdataloader:boolean;
	marketList: any[] = [];
	showConfigurationModel:boolean = false;
	configLabel:string;
	isSiteCodeLoading:boolean;
	isMarketLoading:boolean;
	isBranchLoading:boolean;
	auditConfiguration:AuditResponse;
	auditLoader:boolean;
	initialSearch:boolean;
	auditRequest:AuditRequest;
	minAuditDate:Date;
	showSubmitloader:boolean=false;
	pastAudit:boolean;
	extendauditdays:number;
	configureForm: FormGroup;
	branchList:any;

	constructor(private menubarService:MenubarService,
				private changeDetectorRef:ChangeDetectorRef,
				private adminService: AdministrationService,
				private auditService:AuditService,
				private messageService:MessageService,
				private fb: FormBuilder,
				private userGroupService:UserGroupsService
	) {
	this.menubarService.isCollapsed$
		.pipe(takeUntil(this.destroy$))
		.subscribe((isCollapsed) => {
			this.isCollapsed = isCollapsed;
			this.changeDetectorRef.markForCheck();
		});
	this.isBranchLoading=true;
		this.userGroupService.fetchBranchList()
			.pipe(finalize(()=>this.isBranchLoading = false))
			.subscribe((branchList:BranchModel[])=>{
			this.branchList = branchList;
		})
	}

	ngOnInit(): void {
		this.initialSearch =false;
	}

	public onChangeBranch(): void {
		this.isSiteCodeLoading = true;
		this.marketList = [];
		this.siteCodeTypeList = [];
		this.selectedSiteCodeType = null;
		this.selectedMarket = null;
		this.adminService.getSiteCodeTypes(this.selectedBranch)
			.pipe(
				map((response: any) => response.results.map((item: any) => ({ siteCodeType: item.value }))),
				finalize(() => this.isSiteCodeLoading = false))
			.subscribe((data:any) => {
			this.siteCodeTypeList = data;
		});
	}

	public onChangeSiteCodeType(): void {
		this.isMarketLoading = true;
		this.selectedMarket = null;
		this.initialSearch = false;
		this.adminService.getMarkets(this.selectedBranch,this.selectedSiteCodeType)
			.pipe(
				map((response: any) => response.results.map((item: any) => ({ marketName: item.value }))),
				finalize(() => this.isMarketLoading = false)
			)
			.subscribe(data => {
			this.marketList = data;
		});
	}

	public getAuditConfigration(){
		const baseRequest:BaseModelRequest={
			branch : this.selectedBranch.branchName,
			country : this.selectedMarket.marketName,
			fordSiteCodeType : this.selectedSiteCodeType.siteCodeType,
		}
		this.auditLoader = true;
		this.initialSearch = true;
		this.auditConfiguration = null;
		this.pastAudit = false;
		this.auditService.fetchAuditConfiguration(baseRequest)
			.pipe(
				map((response: any) => ({
					...response,
					auditDueDate: UtilityCommon.parseDate(response.auditDueDate),
					auditStartDate: UtilityCommon.parseDate(response.auditStartDate),
					evaluateSubgroups: response.evaluateSubgroups === 'Y' ? ConditionEnum.Yes : ConditionEnum.No,
					execOverdueRules: response.execOverdueRules === 'Y' ? ConditionEnum.Yes : ConditionEnum.No,
					sendNotification: response.sendNotification === 'Y' ? ConditionEnum.Yes : ConditionEnum.No,
				})),
				finalize(() => {
					this.auditLoader = false;
				})
			)
			.subscribe(
				(auditResponse: any) => {
			if(auditResponse.code == HttpStatusCode.NotFound)
				console.log(auditResponse.message)
			else{
				this.auditConfiguration = auditResponse;
				this.pastAudit = UtilityCommon.getDateDiff(this.auditConfiguration.auditStartDate) >= 0;
			}
		})

	}

	public updateOrCreateConfig(auditRequest:AuditRequest){
		console.log(this.pastAudit,"past audit");
		if(this.configureForm.invalid){
			UtilityCommon.markFormGroupAsDirty(this.configureForm);
			return;
		}
		auditRequest = {
			...auditRequest,
			...this.configureForm.value,
			updateOnly: this.pastAudit
		};
		auditRequest.startDate = UtilityCommon.parseDatetoUTC(auditRequest.startDate);
		this.showSubmitloader = true;

		this.auditService.createOrUpdateAudit(auditRequest)
			.pipe(
				finalize(()=>{this.showSubmitloader =false;this.showConfigurationModel =false}),
				map((response:any)=> response.auditConfigTop),
				map((response: any) => ({
					...response,
					auditDueDate: UtilityCommon.parseDate(response.auditDueDate),
					auditStartDate: UtilityCommon.parseDate(response.auditStartDate),
					evaluateSubgroups: response.evaluateSubgroups === 'Y' ? ConditionEnum.Yes : ConditionEnum.No,
					execOverdueRules: response.execOverdueRules === 'Y' ? ConditionEnum.Yes : ConditionEnum.No,
					sendNotification: response.sendNotification === 'Y' ? ConditionEnum.Yes : ConditionEnum.No,
				})),
			)
			.subscribe((data:AuditResponse)=>{
			this.auditConfiguration = data;
		})
	}


	public extendAuditDate(extentiondays,auditForm:NgForm){
		const auditExtend:AuditExtendRequest ={
			branch : this.selectedBranch.branchName,
			country : this.selectedMarket.marketName,
			fordSiteCodeType : this.selectedSiteCodeType.siteCodeType,
			extensionDays : Number.parseInt(extentiondays)
		}
		if(!(auditExtend.country || extentiondays)){
			return;
		}
		this.showdataloader = true
		this.auditService.updateAuditExtendDate(auditExtend)
			.pipe(finalize(()=> this.showdataloader = false))
			.subscribe((data)=>{
				if(data[0]?.message && data[0]?.message.indexOf('AUDIT EXTENSION INCOMPLETE') != -1){
					this.messageService.add({ summary:'Audit incomplete',detail:'Something went wrong',severity:'error'})
					return;
				}

			this.showExtendAudit = false;
			this.extendauditdays = null;
			this.getAuditConfigration();
			this.changeDetectorRef.markForCheck();
		});
	}

	public showConfigurationDialog(action='button.create',auditRequestObj:AuditResponse=null){
		this.configLabel = action;
		this.pastAudit =false;
		this.auditRequest = {branch : this.selectedBranch.branchName,
			country : this.selectedMarket.marketName,
			fordSiteCodeType : this.selectedSiteCodeType.siteCodeType,
			sendNotification:ConditionEnum.Yes,
			evaluateSubGroup:ConditionEnum.No,
			execOverdueRules:ConditionEnum.No,
			updateOnly:false
		};
		if(action == 'button.update' && auditRequestObj){
			this.auditRequest = {
				...this.auditRequest,
				startDate:auditRequestObj.auditStartDate,
				auditInitInterval:auditRequestObj.auditInitInterval,
				sendNotification:auditRequestObj.sendNotification,
				notificationFreq:auditRequestObj.notificationFrequency,
				evaluateSubGroup:auditRequestObj.evaluateSubgroups,
				execOverdueRules:auditRequestObj.execOverdueRules,
				auditInterval:auditRequestObj.auditIntervalDay,
			};
			this.pastAudit = UtilityCommon.getDateDiff(this.auditRequest.startDate) >= 0;
		}
		this.minAuditDate = new Date();
		this.initializeAuditForm(this.auditRequest);
		this.showConfigurationModel =true;
	}

	initializeAuditForm(auditRequest:AuditRequest){
		this.configureForm = this.fb.group({
			startDate: [{ value: auditRequest.startDate, disabled: this.pastAudit }, Validators.required],
			auditInitInterval: [{ value: auditRequest.auditInitInterval, disabled: this.pastAudit }, [Validators.required, Validators.min(1)]],
			auditInterval: [{ value: auditRequest.auditInterval, disabled: this.pastAudit }, [Validators.required, Validators.min(1)]],
			sendNotification: [auditRequest.sendNotification, Validators.required],
			notificationFreq: [{ value: auditRequest.notificationFreq || 0, disabled: auditRequest.sendNotification === this.ConditionEnum.No }, [Validators.required, Validators.min(1)]]
		},{validators:[auditIntervalValidator,notificationValidator]});

		this.configureForm.get('sendNotification').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
			const notificationFreqControl = this.configureForm.get('notificationFreq');
			if (value === this.ConditionEnum.Yes) {
				notificationFreqControl.enable();
			} else {
				notificationFreqControl.disable();
			}
		});
	}

	getBadgeValue(auditConfig): string {
		return auditConfig.sendNotification === ConditionEnum.Yes
			? `Enabled - ${auditConfig.notificationFrequency} ${auditConfig.notificationFrequency > 1 ? 'days' : 'day'}`
			: 'Disabled';
	}

	onSentNotificationClick(){
		this.configureForm.get('notificationFreq').setValue(0);
	}

	protected readonly ConditionEnum = ConditionEnum;
    protected readonly PageType = PageType;
}
