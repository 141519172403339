import {Component, OnInit} from '@angular/core';
import {UserIdService} from "../../core/adfs/services/user-id.service";
import {AuthService} from "../../core/adfs/services/auth.service";

/** Angular component that displays header for the application
 */
@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit{

	userName:string;

	constructor(private userService:UserIdService,private authService:AuthService) {
	}

	ngOnInit(): void {
		this.userService.getUserName().subscribe(name=>{
			this.userName = name;
		});
		!this.userName && this.authService.setLoggedInUserName();
	}

	logOut(){
		this.authService.logout();
	}

	login(){
		this.authService.login();
	}
}
