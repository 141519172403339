<div *ngIf="!tableLoader;else showLoader">
	<div class="flex gap-3 mt-2 mb-3 justify-content-end" [adminCheck]="PageType.ADMINISTRATORS"  [baseUserGroupModel]="baseUserGroupModel" [buttonGroup]="true">
		<p-button label="{{'button.addAdmin' | translate}}" [disabled]="disablebtn" (click)="openAddUser($event)" />
		<p-button [icon]="loadingDelete ? 'pi pi-spinner pi-spin':'pi pi-trash'" pTooltip="{{'tooltip.deleteAdmin' | translate}}" tooltipPosition="bottom" [disabled]="selectedUser.length == 0 || disablebtn" (click)="confirmDialog($event)"  />
	</div>
	<p-accordion [activeIndex]="0">
		<p-accordionTab [header]=" ('header.administrator' | translate) + ' (' + adminUsers.length + ')' ">
			<div>
				<h4 *ngIf="adminUsers.length == 0; else membertable" class="text-center">
					{{"message.noAdministratorFound" | translate}} </h4>
				<ng-template #membertable>
					<admin-member-table [adminCheck]="PageType.ADMINISTRATORS" [baseUserGroupModel]="baseUserGroupModel" [adminUsers]="adminUsers" (selectedUserEmitter)="onSelectedUser($event)" (deleteUserEmitter)="onDeleteUser($event)" />
				</ng-template>
			</div>
		</p-accordionTab>
	</p-accordion>
</div>
<ng-template #showLoader>
	<div class="text-center h-5rem m-5">
		<p-progressSpinner strokeWidth="5" styleClass="w-5rem h-5rem"
						   ariaLabel="{{'label.loading' | translate}}" animationDuration=".8s"/>
	</div>
</ng-template>


<p-overlayPanel #opadmin [showCloseIcon]="true" [style]="{ 'width':'28vw','padding':'10px','border-radius': '10px'}" class="shadow-2" styleClass="bg-color-2" [dismissable]="true">
	<div class="col-12">
		<div>
			<form class="form" #adminAddFrom="ngForm" (ngSubmit)="addAdminUser(userId,isChecked,adminAddFrom)">
				<div class="relative mb-4">
					<input type="text" autofocus pInputText placeholder="{{'placeholder.enterUserId' | translate}}" pattern="^[a-zA-Z0-9-]*$" id="adminUserId" name="adminUserId" [(ngModel)]="userId" class="w-full" required />
					<div class="absolute">
						<small class="text-red-400" *ngIf="inlineAdminMsg">
							{{inlineAdminMsg}}
						</small>
						<small class="text-red-400" *ngIf="adminAddFrom.controls['adminUserId']?.errors?.pattern && adminAddFrom.controls['adminUserId'].dirty">
							Special characters (except '-') are not allowed.
						</small>
					</div>
				</div>
				<div class="flex justify-content-between  align-items-center">
					<div class="flex gap-2 flex-column">
						<label>{{"label.groupAdmin" | translate}}</label>
						<p-inputSwitch styleClass="scale-down-2x" [(ngModel)]="isChecked"></p-inputSwitch>
					</div>
					<p-button label="{{'button.addUser' | translate}}" [disabled] = "adminAddFrom.invalid" type="submit"></p-button>
				</div>
			</form>
		</div>
		<div *ngIf="inlineLoader" class="card flex justify-content-center spinner-mask">
			<p-progressSpinner strokeWidth="5" styleClass="w-4rem h-4rem"
							   ariaLabel="{{'label.loading' | translate}}" animationDuration=".8s"/>
		</div>
	</div>
</p-overlayPanel>
