export interface AuditExtendRequest {
	branch: string;
	fordSiteCodeType: string;
	country: string;
	extensionDays:number;
}


export interface BaseModelRequest{
	branch: string;
	fordSiteCodeType: string;
	country: string;
}
export enum ConditionEnum {
	Yes = 'Yes',
	No = 'No'
}

export interface AuditResponse {
	auditDueDate?: Date;
	auditExtensionDays?: number;
	auditInitInterval?: number;
	auditIntervalDay?: number;
	auditStartDate?: Date;
	evaluateSubgroups?: ConditionEnum;
	execOverdueRules?: ConditionEnum;
	groupDN?: string;
	notificationFrequency?: number;
	sendNotification?: ConditionEnum;
}

export interface AuditRequest {
	branch?: string;
	fordSiteCodeType?: string;
	country?: string;
	startDate?: Date;
	auditInitInterval?: number;
	auditIntervalDay?: number;
	notificationFreq?: number;
	auditInterval?:number;
	sendNotification?: ConditionEnum;
	evaluateSubGroup?: ConditionEnum;
	execOverdueRules?: ConditionEnum;
	updateOnly:boolean;
}
