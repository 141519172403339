import {
	AbstractControl, FormControl,
	FormGroup,
	ValidationErrors,
	ValidatorFn
} from "@angular/forms";

export function genericValidator(
	pattern: RegExp,
	errorKey: string,
	errorMessage: string,
	length?: number,
	lengthErrorMessage?: string,
	alphanumericPattern?: RegExp,
	alphanumericErrorMessage?: string
): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;

		if (!value) {
			return null;
		}

		if (length && value.length !== length) {
			return {
				[errorKey]: {message: lengthErrorMessage || 'Invalid length'}
			};
		}

		if (alphanumericPattern && !alphanumericPattern.test(value)) {
			return {
				[errorKey]: {
					message: alphanumericErrorMessage || 'Invalid characters'
				}
			};
		}

		const isValid = pattern.test(value);

		if (!isValid) {
			const illegalChars = value.match(pattern);
			if (illegalChars) {
				return {[errorKey]: {message: errorMessage}};
			}
		}

		return null;
	};
}

// Middle Initial Validator
export const middleInitialValidatorBK = genericValidator(
	/^[a-zA-Z0-9]{3}$/, // This regex is a bit redundant now, but kept for structure
	'invalidMiddleInitial',
	'Please enter only alphanumeric characters.',
	3,
	'The middle initial can be 3 characters long.',
	/^[a-zA-Z0-9]+$/,
	'Please enter only alphanumeric characters.'
);

// Mailing Address Validator
export function mailingAddressValidatorBK(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;
		if (!value) {
			return null;
		}
		const valid = /^[^<>&"()#%;+]{1,1024}$/.test(value);
		//return valid ? null : { invalidMailingAddress: 'Please use only alphanumeric characters and spaces. These symbols are not allowed: <>&"()#%;+.' };
		return valid ? null : { invalidMailingAddress: 'message.invalidMailingAddressNew' };
	};
}

// City Validator
export function cityValidatoBK(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;
		if (!value) {
			return null;
		}
		const valid = /^[^<>&"()#%;+]{1,129}$/.test(value);
		//return valid ? null : { invalidCity: 'Please use only alphanumeric characters and spaces. These symbols are not allowed: <>&"()#%;+.' };
		return valid ? null : { invalidCity: 'message.invalidMailingAddressNew' };
	};
}
// State Validator
export function stateValidatorBK(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;
		if (!value) {
			return null;
		}
		const valid = /^[A-Za-z]{2}$/.test(value);
		//return valid ? null : { invalidState: 'Please enter a 2-letter state abbreviation.' };
		return valid ? null : { invalidState: 'message.invalidState' };
	};
}

// Postal Code Validator
export const postalCodeValidatorBK = genericValidator(
	/^[^<>&"()#%;+\-]{1,40}$/,
	'invalidPostalCode',
	'Please use only alphanumeric characters and spaces. These symbols are not allowed: <>&"()#%;+-'
);

export function auditIntervalValidator(
	group: FormGroup
): {[key: string]: any} | null {
	const initInterval = group.get('auditInitInterval').value;
	const auditIntervalControl = group.get('auditInterval');
	const auditInterval = auditIntervalControl.value;
	if (auditInterval <= initInterval) {
		auditIntervalControl.setErrors({
			...auditIntervalControl.errors,
			auditIntervalInvalid: true
		});
		return { auditIntervalInvalid: true }; // Return null since we're setting the error directly on the control
	} else {
		if (auditIntervalControl.hasError('auditIntervalInvalid')) {
			const errors = {...auditIntervalControl.errors};
			delete errors.auditIntervalInvalid; // Remove the specific error
			auditIntervalControl.setErrors(
				Object.keys(errors).length ? errors : null
			); // Set remaining errors or null if none
			return { auditIntervalInvalid: false };
		}
		return null;
	}
}

export function notificationValidator(
	group: FormGroup
): {[key: string]: any} | null {
	const initInterval = group.get('auditInitInterval').value;
	const notificationFreq = group.get('notificationFreq');
	const notificationFreqVal = notificationFreq.value;
	if (notificationFreqVal < initInterval) {
		if (notificationFreq.hasError('notificationFreqInvalid')) {
			const errors = {...notificationFreq.errors};
			delete errors.notificationFreqInvalid; // Remove the specific error
			notificationFreq.setErrors(
				Object.keys(errors).length ? errors : null
			); // Set remaining errors or null if none
			return { notificationFreqInvalid: false };
		}
		return null;
	} else {
		notificationFreq.setErrors({
			...notificationFreq.errors,
			notificationFreqInvalid: true
		});
		return { notificationFreqInvalid: true };
	}
}

// Name Validator
export const nameValidatorBK = genericValidator(
	/^(?=.*[a-zA-Z0-9])[^<>&"()#%;+]{1,64}$/,
	'invalidName',
	'Please use only alphanumeric characters and spaces. These symbols are not allowed: <>&"()#%;+',
	undefined,
	undefined,
	/[a-zA-Z0-9]/,
	'Please include at least one alphanumeric character.'
);

// Phone/Fax Validator
export function phoneFaxValidatorBK(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const valid = /^[a-zA-Z0-9()+./#]*$/.test(control.value);
		//return valid ? null : { invalidFax: 'Please use only alphanumeric characters and these symbols: ()+ ./#' };
		return valid ? null : { invalidFax: 'message.invalidPhoneFax' };
	};
}

export function postalValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;
		if (!value) {
			return null;
		}
		const valid = /^[^<>&"()#%;+\-]{1,40}$/.test(value);
		//return valid ? null : { invalidPostalCode: 'Please use only alphanumeric characters and spaces. These symbols are not allowed: <>&"()#%;+-.' };
		return valid ? null : { invalidPostalCode: 'message.invalidPostalNew' };
	};
}

// Telephone Number Validator
export function telephoneNumberValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;
		if (!value) {
			// Allow empty values
			return null;
		}
		const valid = /^[a-zA-Z0-9()+./#]*$/.test(value);
		//return valid ? null : { invalidTelephoneNumber: 'Please use only alphanumeric characters and these symbols: ()+ ./#' };
		return valid ? null : { invalidTelephoneNumber: 'message.invalidPhoneFax' };
	};
}

// Fax Validator
export function faxValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;
		if (!value) {
			// Allow empty values
			return null;
		}
		const valid = /^[a-zA-Z0-9()+./#]*$/.test(value);
		//return valid ? null : { invalidFax: 'Please use only alphanumeric characters and these symbols: ()+ ./#' };
		return valid ? null : { invalidFax: 'message.invalidPhoneFax' };
	};
}


export function nameValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const nameRegex = /^(?=.*[a-zA-Z0-9])[^<>&"()#%;+]{1,64}$/;
		const value = control.value;
		if (!value) {
			return null; // No value, no validation
		}
		const isValid = nameRegex.test(value);
		if (!isValid) {
			// Find illegal characters
			const illegalChars = value.match(/[\<>&"()#%;+]/g);
			if (illegalChars) {
				return {'invalidName': {'illegalChars': illegalChars.join(', ')}};
			}
		}
		// Check for alphanumeric characters
		if (!/[a-zA-Z0-9]/.test(value)) {
			return {'missingAlphanumeric': true};
		}
		return null; // Valid
	}
}
export function middleInitialValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;

		if (!value) {
			return null; // No value, no validation
		}

		const middleInitialRegex = /^[a-zA-Z0-9]{3}$/;
		const isValid = middleInitialRegex.test(value);

		if (!isValid) {
			if (value.length > 3) {
				//return {'invalidMiddleInitial': {'message': 'The middle initial can be 3 characters long.'}};
				return {'invalidMiddleInitial': {'message': 'message.middleInitial'}};
			} else if (!/^[a-zA-Z0-9]+$/.test(value)) {
				//return {'invalidMiddleInitial': {'message': 'Please enter only alphanumeric characters.'}};
				return {'invalidMiddleInitial': {'message': 'message.alphanumericCharacters'}};
			}
		}

		return null; // Valid
	}
}
export function phoneFaxValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;

		if (!value) {
			return null; // No value, no validation
		}
		const phoneFaxRegex = /^[-A-Za-z0-9()\+ \/.#]{1,64}$/;
		const isValid = phoneFaxRegex.test(value);

		if (!isValid) {
			// Find illegal characters
			const illegalChars = value.match(/[^A-Za-z0-9()\+ \/.#]/g);
			if (illegalChars) {
				//return { 'invalidPhoneFax': { 'message': 'Please use only alphanumeric characters and these symbols: -\+ /.#' } };
				return { 'invalidPhoneFax': { 'message': 'message.invalidPhoneFaxNew' } };
			}
		}

		return null; // Valid
		}
}
export function mailingAddressValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;

		if (!value) {
			return null; // No value, no validation
		}

		const mailingAddressRegex = /^[^<>&"()#%;+]{1,1024}$/;
		const isValid = mailingAddressRegex.test(value);

		if (!isValid) {
			// Find illegal characters
			const illegalChars = value.match(/[\<>&"()#%;+]/g);
			if (illegalChars) {
				//return {'invalidMailingAddress': {'message': 'Please use only alphanumeric characters and spaces. These symbols are not allowed: <>&"()#%;+.'}};
				return {'invalidMailingAddress': {'message': 'message.invalidMailingAddress'}};
			}
		}

		return null; // Valid
	}
}
export function cityValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;

		if (!value) {
			return null; // No value, no validation
		}

		const cityRegex = /^[^<>&"()#%;+]{1,129}$/;
		const isValid = cityRegex.test(value);

		if (!isValid) {
			// Find illegal characters
			const illegalChars = value.match(/[\<>&"()#%;+]/g);
			if (illegalChars) {
				//return {'invalidCity': {'message': 'Please use only alphanumeric characters and spaces. These symbols are not allowed: <>&"()#%;+'}};
				return {'invalidCity': {'message': 'message.invalidMailingAddress'}};
			}
		}

		return null; // Valid
	}
}
export function stateValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;

		if (!value) {
			return null; // No value, no validation
		}

		const stateRegex = /^[A-Za-z]{2}$/;
		const isValid = stateRegex.test(value);

		if (!isValid) {
			//return {'invalidState': {'message': 'Please enter a 2-letter state abbreviation.'}};
			return {'invalidState': {'message': 'message.invalidState'}};
		}

		return null; // Valid
	}
}
export function postalCodeValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;

		if (!value) {
			return null; // No value, no validation
		}

		const postalCodeRegex = /^[^<>&"()#%;+\-]{1,40}$/;
		const isValid = postalCodeRegex.test(value);

		if (!isValid) {
			// Find illegal characters
			const illegalChars = value.match(/[\<>&"()#%;+\-]/g);
			if (illegalChars) {
				//return {'invalidPostalCode': {'message': 'Please use only alphanumeric characters and spaces. These symbols are not allowed: <>&"()#%;+-'}};
				return {'invalidPostalCode': {'message': 'message.invalidPostal'}};
			}
		}

		return null; // Valid
	}
}
