<app-title></app-title>
	<div class="card flex justify-content-center h-5rem m-8" *ngIf="infoLoader">
		<p-progressSpinner strokeWidth="5" styleClass="w-5rem h-5rem"
							 ariaLabel="{{'label.loading' | translate}}" animationDuration=".8s"/>
	</div>
<form *ngIf="editForm && !infoLoader" [formGroup]="editForm">
	<p-tabView (onChange)="onChangeTab($event)">
		<p-tabPanel header="{{'header.profile' | translate}}">
			<p-fieldset [toggleable]="true">
				<ng-template pTemplate="header">
					<div class="flex align-items-center">
						<span class="pi pi-user mr-2"></span>
						<span class="font-bold text-lg">{{"header.basicInfo" | translate}}</span>
					</div>
				</ng-template>
				<div class="card">
					<div class="flex flex-wrap mb-4">
						<div class="col-4">
							<div class="flex justify-content-start">
								<label for="alphabetic" class="font-bold block mb-2">{{"label.userID" | translate}}</label>
							</div>
							<input pInputText formControlName="userId" [ngClass]="{readonly: true}" [readonly]="true" class="w-full" />
						</div>
						<div class="col-4">
							<div class="flex justify-content-start">
								<label for="alphabetic" class="required-label font-bold block mb-2">{{"label.firstName" | translate}}</label>
							</div>
							<input type="text" pInputText id="firstName"  formControlName="firstName" class="w-full" />
							<div>
								<small class="text-red-400" *ngIf="editForm.get('firstName')?.hasError('missingAlphanumeric')">
									{{ editForm.get("firstName")?.getError("missingAlphanumeric") }}
								</small>
								<small class="text-red-400" *ngIf="editForm.get('firstName').hasError('maxlength')">
									{{"message.nameLengthMessage" | translate}}
								</small>
								<div *ngIf="editForm.get('firstName')?.hasError('invalidName')">
									{{ editForm.get("firstName")?.getError("invalidName") }}
								</div>
							</div>
						</div>
						<div class="col-4">
							<div class="flex justify-content-start">
								<label for="alphabetic" class="required-label font-bold block mb-2">{{"label.lastName" | translate}}</label>
							</div>
							<input pInputText id="alphabetic" formControlName="lastName" class="w-full" />
							<div class="relative">
								<div class="absolute">
									<small class="text-red-400" *ngIf="editForm.get('lastName')?.hasError('invalidName')">
										{{ editForm.get("lastName")?.getError("invalidName") }}
									</small>
									<small class="text-red-400" *ngIf="editForm.get('lastName').hasError('maxlength')">
										{{"message.nameLengthMessage" | translate}}
									</small>
									<div *ngIf="editForm.get('lastName')?.hasError('missingAlphanumeric')">
										{{ editForm.get("lastName")?.getError("missingAlphanumeric") }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="flex flex-wrap">
						<div class="col-4">
							<div class="flex justify-content-start">
								<label for="alphabetic" class="required-label font-bold block mb-2">{{"label.email" | translate}}</label>
							</div>
							<input pInputText id="alphabetic" formControlName="mail" class="w-full" />
							<div>
								<small class="text-red-400" *ngIf="(editForm.get('mail')?.hasError('email') || editForm.get('mail').hasError('pattern')) && !editForm.get('mail')?.hasError('required')">
									{{ editForm.get("mail")?.getError("email") }}
								</small>
								<small class="text-red-400" *ngIf="editForm.get('mail')?.hasError('required') && editForm.get('mail')?.touched">
									{{ editForm.get("mail")?.getError("required") }}
								</small>
								<small class="text-red-400" *ngIf="editForm.get('mail').hasError('maxlength')">
									{{"message.emailLengthMessage" | translate}}
								</small>
							</div>
							<p class="highlight">{{"message.emailMustUnique" | translate}}</p>
						</div>
						<div class="col-4">
							<div class="flex justify-content-start">
								<label for="alphabetic" class="font-bold block mb-2">{{"label.jobRole" | translate}}</label>
							</div>
							<ng-container *ngIf="isMultiSelect(); else dropdownTemplate">
								<p-multiSelect
									styleClass="w-full"
									[options]="jobRolesList"
									formControlName="fordJobRole"
									optionLabel="label"
									optionValue="value"
									[showClear]="enableJobrole"
									placeholder="{{'placeholder.selectJobRole' | translate}}" [readonly]="!enableJobrole"
									>
								</p-multiSelect>
							</ng-container>
							<ng-template #dropdownTemplate>
								<p-dropdown
									styleClass="w-full"
									[options]="jobRolesList"
									formControlName="fordJobRole"
									optionLabel="label"
									optionValue="value"
									[showClear]="enableJobrole"
									placeholder="{{'placeholder.selectJobRole' | translate}}" [readonly]="!enableJobrole">
								</p-dropdown>
							</ng-template>
						</div>
						<div class="col-4">
							<div class="flex justify-content-start">
								<label for="alphabetic" class="font-bold block mb-2">{{"label.preferredLanguage" | translate}}</label>
							</div>
							<p-dropdown
								styleClass="w-full"
								[options]="language"
								optionLabel="languageName"
								optionValue="languageCode"
								formControlName="language"
								[showClear]="true"
								(onChange)="onLanguageChange($event)"
								[placeholder]="editForm.get('language')?.value?.languageName || 'Select Language'">
							</p-dropdown>
						</div>
					</div>
					<div class="flex flex-wrap">
						<div class="col-4">
							<div class="flex justify-content-start">
								<label for="alphabetic" class="required-label font-bold block mb-2">{{"label.country" | translate}}</label>
							</div>
							<input pInputText id="alphabetic" formControlName="country" class="w-full" />
							<small class="text-red-400" *ngIf="editForm.get('country')?.hasError('required') && editForm.get('country')?.touched">
								{{"message.countryRequired" | translate}}
							</small>
						</div>
						<div class="col-4">
							<div class="flex justify-content-start">
								<label for="alphabetic" class="required-label font-bold block mb-2">{{"label.fordSiteCode" | translate}}</label>
							</div>
							<input pInputText id="alphabetic" formControlName="fordSiteCode" class="w-full" />
							<small class="text-red-400" *ngIf="editForm.get('fordSiteCode')?.hasError('required') && editForm.get('fordSiteCode')?.touched">
								{{"message.fordSiteCodeRequired" | translate}}
							</small>
						</div>
						<div class="col-4">
							<div class="flex justify-content-start">
								<label for="alphabetic" class="required-label font-bold block mb-2">{{"label.fordSiteCodeType" | translate}}</label>
							</div>
							<input pInputText id="alphabetic" formControlName="fordSiteCodeType" class="w-full" />
							<small class="text-red-400" *ngIf="editForm.get('fordSiteCodeType')?.hasError('required') && editForm.get('fordSiteCodeType')?.touched">
								{{"message.fordSiteCodeTypeRequired" | translate}}
							</small>
						</div>
					</div>
					</div>
			</p-fieldset>
			<br />
			<p-fieldset [toggleable]="true">
				<ng-template pTemplate="header">
					<div class="flex align-items-center">
						<span class="pi pi-user mr-2"></span>
						<span class="font-bold text-lg">{{"header.contactInfo" | translate}}</span>
					</div>
				</ng-template>
				<div class="card">
					<div class="flex flex-wrap mb-4">
						<div class="col-4">
							<div class="flex justify-content-start">
								<label for="alphabetic" class="font-bold block mb-2">{{"label.telephone" | translate}}</label>
							</div>
							<input pInputText id="alpha" formControlName="telephoneNumber" class="w-full" />
							<small class="text-red-400" *ngIf="editForm.get('telephoneNumber')?.hasError('invalidTelephoneNumber')">
								{{ editForm.get("telephoneNumber")?.getError("invalidTelephoneNumber") | translate }}
							</small>
						</div>
						<div class="col-4">
							<label for="alphabetic" class="font-bold block mb-2">{{"label.fax" | translate}}</label>
							<input pInputText id="alpha" formControlName="fax" class="w-full" />
							<small class="text-red-400" *ngIf="editForm.get('fax')?.hasError('invalidFax')">
								{{ editForm.get("fax")?.getError("invalidFax") | translate }}
							</small>
						</div>
						<div class="col-4">
							<div class="flex justify-content-start">
								<label for="alphabetic" class="font-bold block mb-2">{{"label.streetAddress" | translate}}</label>
							</div>
							<input pInputText id="alphabetic" formControlName="postalAddress" class="w-full" />
							<small class="text-red-400" *ngIf="editForm.get('postalAddress')?.hasError('invalidMailingAddress')">
								{{ editForm.get("postalAddress")?.getError("invalidMailingAddress")?.message | translate }}
							</small>
						</div>
					</div>
					<div class="flex flex-wrap">
						<div class="col-4">
							<div class="flex justify-content-start">
								<label for="alphabetic" class="font-bold block mb-2">{{"label.city" | translate}}</label>
							</div>
							<input pInputText id="alphabetic" formControlName="city" class="w-full" />
							<small class="text-red-400" *ngIf="editForm.get('city')?.hasError('invalidCity')">
								{{ editForm.get("city")?.getError("invalidCity")?.message | translate }}
							</small>
						</div>
						<div class="col-4">
							<div class="flex justify-content-start">
								<label for="alphabetic" class="font-bold block mb-2">{{"label.stateOrProvince" | translate}}</label>
							</div>
							<input pInputText id="alphabetic" formControlName="state" class="w-full" />
							<small class="text-red-400" *ngIf="editForm.get('state')?.hasError('invalidState')">
								{{ editForm.get("state")?.getError("invalidState")?.message | translate }}
							</small>
						</div>
						<div class="col-4">
							<div class="flex justify-content-start">
								<label for="alphabetic" class="font-bold block mb-2">{{"label.postalOrZipCode" | translate}}</label>
							</div>
							<input pInputText id="alphabetic" formControlName="postalCode" class="w-full" />
							<small class="text-red-400" *ngIf="editForm.get('postalCode')?.hasError('invalidPostalCode')">
								{{ editForm.get("postalCode")?.getError("invalidPostalCode") | translate }}
							</small>
						</div>
					</div>
				</div>
			</p-fieldset>
			<br />
		</p-tabPanel>
		<p-tabPanel header="{{'header.groups' | translate}}">
			<ng-template #showdata>
				<p class="text-center mt-8">
					<p-progressSpinner strokeWidth="5" styleClass="w-5rem h-5rem" ariaLabel="loading" animationDuration=".8s" />
				</p>
			</ng-template>
			<div *ngIf="!loadingGroup; else showdata">
				<div *ngIf="groupsList && groupsList.length != 0; else nouser">
					<p class="text-right p-0 m-0 mb-2 text-sm font-bold text-color-2 indicate-primary">
						<span class="pi pi-home text-primary text-sm text-color-1 primary-icon"></span>{{"label.primaryLocation" | translate}}
					</p>
					<p-table [value]="groupsList" scrollHeight="50vh" [scrollable]="true" [paginator]="true" [rows]="EnvConstants.PAGINATION_LENGTH" [tableStyle]="{'min-width': '50rem'}"
							 [rowsPerPageOptions]="EnvConstants.PAGINATION_ROW" dataKey="location">
						<ng-template pTemplate="header">
							<tr>
								<th>{{"label.siteCode" | translate}}</th>
								<th>{{"label.description" | translate}}</th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-group>
							<tr>
								<td>
									{{ group.location }}
									<span class="pi pi-home text-primary text-sm text-color-1 primary-icon" *ngIf="group.primary"></span>
								</td>
								<td>
									{{ group.description }}
								</td>
							</tr>
						</ng-template>
					</p-table>
				</div>
			</div>
			<ng-template #nouser>
				<h4 class="text-center mt-7">{{"message.noGroupRecordsFound" | translate}}</h4>
			</ng-template>
		</p-tabPanel>
	</p-tabView>
	<div class="flex justify-content-center mt-4">
		<p-button icon="pi pi-check" [loading]="loading" label="{{'button.save' | translate}}" (click)="saveUser()" styleClass="p-button-text"></p-button>
	</div>
</form>
