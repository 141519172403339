<form *ngIf="editForm" [formGroup]='editForm'>
  <p-tabView (onChange)="onChangeTab($event)" [(activeIndex)]="activeIndex">
    <p-tabPanel header="{{'header.profile' | translate}}">
      <p-fieldset [toggleable]="true">
        <ng-template pTemplate="header">
          <div class="flex align-items-center">
            <span class="pi pi-user mr-2"></span>
            <span class="font-bold text-lg">{{"header.basicInfo" | translate}}</span>
          </div>
        </ng-template>
        <div class="card">
          <div class="flex flex-wrap mb-4">
            <div class="col-4">
              <div class="flex justify-content-start">
                <label for="alphabetic" class="font-bold block mb-2"> {{"label.userID" | translate}}
                </label>
              </div>
              <input pInputText pKeyFilter="alpha" formControlName="userId" [ngClass]="{'readonly': true}"
                [readonly]="true" class="w-full" />
            </div>

            <div class="col-4">
              <div class="flex justify-content-start">
                <label for="alphabetic" class="required-label font-bold block mb-2"> {{"label.firstName" | translate}}
                </label>

              </div>
              <input type="text" pInputText id="firstName" pKeyFilter="alpha" formControlName="firstName"
                 class="w-full" />
              <div>
                <small class="text-red-400"
                  *ngIf="editForm.get('firstName').hasError('required') && editForm.get('firstName').touched">
                  {{"message.firstNameRequired" | translate}}
                </small>
                <small class="text-red-400" *ngIf="editForm.get('firstName').hasError('missingAlphanumeric')">
                  {{"message.firstNameOneLetter" | translate}}
                </small>
				  <small class="text-red-400" *ngIf="editForm.get('firstName').hasError('maxlength')">
					  {{"message.nameLengthMessage" | translate}}
				  </small>
                <div *ngIf="editForm.get('firstName').hasError('invalidName')">
                  <small class="text-red-400" *ngIf="editForm.get('firstName').getError('invalidName').illegalChars">
                    {{"message.illegalCharacters" | translate}}: {{
                    editForm.get('firstName').getError('invalidName').illegalChars }}
                  </small>
                </div>
              </div>

            </div>
            <div class="col-4">
              <div class="flex justify-content-start"><label for="alphabetic"
                  class="required-label font-bold block mb-2"> {{"label.lastName" | translate}}
                </label></div>
              <input pInputText id="alphabetic"  pKeyFilter="alpha"
                formControlName="lastName" class="w-full" />
              <div class="relative">
                <div class="absolute">
                  <small class="text-red-400"
                    *ngIf="editForm.get('lastName').hasError('required') && editForm.get('lastName').touched">
                    {{"message.lastNameRequired" | translate}}
                  </small>
					<small class="text-red-400" *ngIf="editForm.get('lastName').hasError('maxlength')">
						{{"message.nameLengthMessage" | translate}}
					</small>
                  <div *ngIf="editForm.get('lastName').hasError('invalidName')">
                    <small class="text-red-400" *ngIf="editForm.get('lastName').getError('invalidName').illegalChars">
                      {{"message.illegalCharacters" | translate}}: {{
                      editForm.get('lastName').getError('invalidName').illegalChars }}
                    </small>
                  </div>
                  <small class="text-red-400" *ngIf="editForm.get('lastName').hasError('missingAlphanumeric')">
                    {{"message.lastNameOneLetter" | translate}}
                  </small>
                </div>
              </div>
            </div>

          </div>
          <div class="flex flex-wrap">
            <div class="col-4">
              <div class="flex justify-content-start"><label for="alphabetic"
                  class="required-label font-bold block mb-2"> {{"label.userEmail" | translate}}
                </label></div>
              <input pInputText id="alphabetic" pKeyFilter="alpha"
                formControlName="mail" class="w-full" />
              <div>
                <small class="text-red-400"
                  *ngIf="(editForm.get('mail').hasError('email') || editForm.get('mail').hasError('pattern'))&& !editForm.get('mail').hasError('required')">
                  {{"message.validEmail" | translate}}
                </small>
                <small class="text-red-400"
                  *ngIf="editForm.get('mail').hasError('required') && editForm.get('mail').touched">
                  {{"message.emailRequired" | translate}}
                </small>
				  <small class="text-red-400" *ngIf="editForm.get('mail').hasError('maxlength')">
					  {{"message.emailLengthMessage" | translate}}
				  </small>
              </div>
              <p class="highlight">{{"message.uniqueEmail" | translate}}
              </p>
            </div>
            <div class="col-4">
              <div class="flex justify-content-start"><label for="alphabetic"
                [class]="jobRoleRequired ? 'font-bold block mb-2 required-label'  : 'font-bold block mb-2' "> {{"label.jobRole" | translate}}
                </label></div>
				<ng-container *ngIf="multiselect;else singleselect">
					<p-multiSelect styleClass="w-full mr-4" [options]="jobRolesList" formControlName="fordJobRole"
								   optionLabel="jobRoleName"
								   placeholder="{{'placeholder.selectJobRole' | translate}}" [showClear]="true"></p-multiSelect>
				</ng-container>
				<ng-template #singleselect>
					<p-dropdown styleClass="w-full mr-4" [options]="jobRolesList" formControlName="fordJobRole"
								optionLabel="jobRoleName" [filter]="true" filterBy="jobRoleName,jobRoleCode"
								placeholder="{{'placeholder.selectJobRole' | translate}}" [showClear]="true"></p-dropdown>
				</ng-template>

            </div>
            <div class="col-4">
              <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2 required-label">
                  {{"label.preferredLanguage" | translate}}
                </label>
              </div>
              <p-dropdown styleClass="w-full" [options]="language" formControlName="language" optionLabel="languageName"
                 [showClear]="true" [filter]="true" filterBy="languageName"
                placeholder="{{'placeholder.selectLanguage' | translate}}"></p-dropdown>
            </div>
            <!-- <div class="col-4">
              <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2"> Preferred
                  Language </label><span class="text-red-500"> *</span></div>
              <p-dropdown styleClass="w-full" [options]="language" formControlName="language" optionLabel="languageName"
                [showClear]="true" placeholder="Select a Language"></p-dropdown>
            </div> -->
          </div>
          <div class="flex flex-wrap" *ngIf="!hideGroup && superAdmin">
            <div class="col-4">
              <div class="flex justify-content-start"><label for="alphabetic"
                  class=" required-label font-bold block mb-2"> {{"label.country" | translate}}
                </label>
              </div>
              <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="country"
                 class="w-full" />
              <small class="text-red-400"
                *ngIf="editForm.get('country').hasError('required') && editForm.get('country').touched">
                {{"message.countryRequired" | translate}}
              </small>
            </div>
            <div class="col-4">
              <div class="flex justify-content-start">
                <label for="alphabetic" class="required-label font-bold block mb-2"> {{"label.fordSiteCode" |
                  translate}}</label>
              </div>
              <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="fordSiteCode"
                 class="w-full" />
              <small class="text-red-400"
                *ngIf="editForm.get('fordSiteCode').hasError('required') && editForm.get('fordSiteCode').touched">
                {{"message.fordSiteCodeRequired" | translate}}
              </small>
            </div>
            <div class="col-4">
              <div class="flex justify-content-start">
                <label for="alphabetic" class="required-label font-bold block mb-2"> {{"label.fordSiteCodeType" |
                  translate}} </label>
              </div>
              <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="fordSiteCodeType"
                 class="w-full" />
              <small class="text-red-400"
                *ngIf="editForm.get('fordSiteCodeType').hasError('required') && editForm.get('fordSiteCodeType').touched">
                {{"message.fordSiteCodeTypeRequired" | translate}}
              </small>
            </div>
          </div>
        </div>
      </p-fieldset>
      <br>
      <p-fieldset [toggleable]="true">
        <ng-template pTemplate="header">
          <div class="flex align-items-center">
            <span class="pi pi-user mr-2"></span>
            <span class="font-bold text-lg">{{"header.contactInfo" | translate}}</span>
          </div>
        </ng-template>
        <div class="card">
          <div class="flex flex-wrap mb-4">
            <div class="col-4">
              <div class="flex justify-content-start">
                <label for="alphabetic" class="font-bold block mb-2"> {{"label.telephone" | translate}} </label>
                <!-- <span class="text-red-500"> *</span> -->
              </div>
              <input type="text" pInputText id="telephoneNumber" pKeyFilter="alpha" formControlName="telephoneNumber"
                 class="w-full" />
              <small class="text-red-400" *ngIf="editForm.get('telephoneNumber').hasError('invalidPhoneFax')">
                {{ editForm.get('telephoneNumber').getError('invalidPhoneFax').message | translate}}
              </small>
              <small class="text-red-400" *ngIf="editForm.get('telephoneNumber')?.hasError('maxlength')">
                {{"message.phoneLengthMessage" | translate}}
             </small>
            </div>
            <div class="col-4">
              <label for="alphabetic" class="font-bold block mb-2"> {{"label.fax" | translate}} </label>
              <input pInputText id="alpha" pKeyFilter="alpha" formControlName="fax"
                 class="w-full" />
              <small class="text-red-400" *ngIf="editForm.get('fax').hasError('invalidPhoneFax')">
                {{ editForm.get('fax').getError('invalidPhoneFax').message | translate}}
              </small>
            </div>
            <div class="col-4">
              <div class="flex justify-content-start">
                <label for="alphabetic" class="font-bold block mb-2"> {{"label.streetAddress" | translate}} </label>
                <!-- <span class="text-red-500"> *</span> -->
              </div>
              <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="postalAddress"
                 class="w-full" />
              <small class="text-red-400" *ngIf="editForm.get('postalAddress').hasError('invalidMailingAddress')">
                {{ editForm.get('postalAddress').getError('invalidMailingAddress').message | translate }}
              </small>
              <small class="text-red-400" *ngIf="editForm.get('postalAddress').hasError('maxlength')">
                {{"message.postalAddressLengthMessage" | translate}}
             </small>
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="col-4">
              <div class="flex justify-content-start">
                <label for="alphabetic" class="font-bold block mb-2"> {{"label.city" | translate}} </label>
                <!-- <span class="text-red-500"> *</span> -->
              </div>
              <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="city"
                 class="w-full" />
              <small class="text-red-400" *ngIf="editForm.get('city').hasError('invalidCity')">
                {{ editForm.get('city').getError('invalidCity').message | translate }}
              </small>
            </div>
            <div class="col-4">
              <div class="flex justify-content-start">
                <label for="alphabetic" class="font-bold block mb-2"> {{"label.stateOrProvince" | translate}}</label>
                <!-- <span class="text-red-500"> *</span> -->
              </div>
              <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="state"
                 class="w-full" />
              <small class="text-red-400" *ngIf="editForm.get('state').hasError('invalidState')">
                {{ editForm.get('state').getError('invalidState').message | translate }}
              </small>
            </div>
            <div class="col-4">
              <div class="flex justify-content-start">
                <label for="alphabetic" class="font-bold block mb-2"> {{"label.postalOrZipCode" | translate}} </label>
                <!-- <span class="text-red-500"> *</span> -->
              </div>
              <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="postalCode"
                 class="w-full" />
              <small class="text-red-400" *ngIf="editForm.get('postalCode').hasError('invalidPostalCode')">
                {{ editForm.get('postalCode').getError('invalidPostalCode').message | translate}}
              </small>
            </div>
          </div>
        </div>
      </p-fieldset>
      <br>
      <p-fieldset [toggleable]="true" *ngIf="!hideGroup && superAdmin">
        <ng-template pTemplate="header">
          <div class="flex align-items-center">
            <span class="pi pi-user mr-2"></span>
            <span class="font-bold text-lg">{{"header.fMCCInfo" | translate}}</span>
          </div>
        </ng-template>
        <div class="card">
          <div class="flex flex-wrap mb-4">
            <div class="col-3">
              <label for="alphabetic" class="font-bold block mb-2"> {{"label.fMCCBCode" | translate}} </label>
              <input type="text" pInputText id="alpha" pKeyFilter="alpha" formControlName="fmccBCode"
                 class="w-full" />
            </div>
            <div class="col-3">
              <label for="alphabetic" class="font-bold block mb-2"> {{"label.fMCCBrand" | translate}} </label>
              <input pInputText id="alpha" pKeyFilter="alpha" formControlName="fmccBrand"
                 class="w-full" />

            </div>
            <div class="col-3">
              <label for="alphabetic" class="font-bold block mb-2"> {{"label.fMCCClass" | translate}} </label>
              <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="fmccClass"
                 class="w-full" />

            </div>
            <div class="col-3">
              <label for="alphabetic" class="font-bold block mb-2"> {{"label.fMCCFinLab" | translate}}</label>
              <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="fmccFinlab"
                 class="w-full" />
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="col-3">
              <label for="alphabetic" class="font-bold block mb-2"> {{"label.fMCCLessor" | translate}}</label>
              <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="fmccLessor"
                 class="w-full" />
            </div>
            <div class="col-3">
              <label for="alphabetic" class="font-bold block mb-2"> {{"label.fMCCMkt" | translate}} </label>
              <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="fmccMkt"
                 class="w-full" />
            </div>
            <div class="col-3">
              <label for="alphabetic" class="font-bold block mb-2"> {{"label.fMCCFinLab" | translate}} </label>
              <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="fmccRegion"
                 class="w-full" />
            </div>
            <div class="col-3">
              <label for="alphabetic" class="font-bold block mb-2"> {{"label.fMCCSBCode" | translate}} </label>
              <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="fmccSBCode"
                 class="w-full" />
            </div>
          </div>
        </div>
      </p-fieldset>
      <div class="card">
        <ng-template pTemplate="header">
          <div class="flex align-items-center">
            <span class="pi pi-user mr-2"></span>
            <span class="font-bold text-lg">{{"label.status" | translate}} </span>
          </div>
        </ng-template>
        <div id="createUsersSelect" class="col-4">
          <label for="alphabetic" class="font-bold block mb-2 required-label"> {{"label.status" | translate}} </label>
          <div class="relative">
          <p-selectButton [options]="statusOptions" formControlName="status" optionLabel="label" optionValue="status"
            [disabled]="!editUser && !superAdmin"></p-selectButton>
            <div class="absolute">
              <small class="text-red-400" *ngIf="editForm.get('status').hasError('required') && editForm.get('status').touched">
                {{"message.statusRequired" | translate}}
              </small>
            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="{{'header.groups' | translate}}">
      <ng-template #showdata>
        <p class="text-center mt-8">
          <p-progressSpinner strokeWidth="5" styleClass="w-5rem h-5rem" ariaLabel="loading" animationDuration=".8s" />
        </p>
      </ng-template>
      <div *ngIf="!loadingGroup;else showdata">
        <div *ngIf="groupsList && groupsList.length != 0 ;else nouser;">
          <p class="text-color-2 indicate-primary">
            <span class="pi pi-home primary-icon"></span> {{"label.primaryLocation" | translate}}
          </p>
          <p-table #dt [value]="groupsList" scrollHeight="50vh" [scrollable]="true" [paginator]="true" [rows]="EnvConstants.PAGINATION_LENGTH"
            [tableStyle]="{ 'min-width': '50rem' }" [rowsPerPageOptions]="EnvConstants.PAGINATION_ROW" dataKey="location">
            <ng-template pTemplate="header">
              <tr>
                <th>{{"label.siteCode" | translate}}</th>
                <th>{{"label.description" | translate}}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-group>
              <tr>
                <td>
                  {{group.location}}
                  <span class="pi pi-home primary-icon" *ngIf="group.primary"></span>
                </td>
                <td>
                  {{group.description}}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>

      <ng-template #nouser>
        <h4 class="text-center mt-7">
          {{"message.noGroupRecordFound" | translate}}
        </h4>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel *ngIf="emailTypes.length > 0" header="{{'header.emailPreferences' | translate}}">
      <div class="email-preference col-7 mx-auto">
        <div class="flex col-12 gap-4 mb-4 mt-1 justify-content-center">
          <div class="grid align-items-center m-0 shadow-1 bg-color-7 info-card font-bold p-4 gap-3 col-5">
            <label class="text-color-1">{{"label.userID" | translate}} :</label>
            <div>
              <span>{{editForm.get('userId')?.value}}</span>
            </div>
          </div>
          <div class="grid align-items-center m-0 shadow-1 bg-color-7 info-card font-bold p-4 gap-3 col">
            <label class="text-color-1">{{"label.primaryEmail" | translate}}:</label>
            <div>
              <span>{{editForm.get('mail')?.value || ' - '}}</span>
            </div>
          </div>
        </div>
        <p-fieldset legend="{{'header.emailPreferences' | translate}}">
          <div class="flex col-12 justify-content-center">
            <div class="col-4 flex flex-column">
              <label class="text-color-1 font-bold mb-2">{{"label.emailSubscription" | translate}}:</label>
              <div>
                <p-inputSwitch  formControlName="mailHost"></p-inputSwitch>
              </div>
            </div>
            <div class="col-8 flex flex-column">
              <label class="text-color-1 font-bold mb-2">{{"label.emailType" | translate}}:</label>
              <div>
                <p-multiSelect styleClass="w-full" [options]="emailTypes" (onChange)="onChangeEmailType($event)"
                  formControlName="emailType" optionLabel="name" optionValue="code"
                   placeholder="Select Type"></p-multiSelect>
                <div *ngIf="editForm.get('emailType').value" class="w-full grid gap-1 col-8 mt-1 pt-0">
                  <ng-container *ngFor="let emailtype of editForm.get('emailType').value">
                    <p-chip [label]="emailtype"></p-chip>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </p-fieldset>

      </div>
    </p-tabPanel>
    <p-tabPanel *ngIf="auditStatus" header="{{'label.audit' | translate}}">
      <div class="col-5 my-4">
        <div class="audit-card shadow-1 bg-color-7 border-round-2xl flex align-items-center justify-content-center">
          <div id="audit-status" class="grid p-5 align-items-center font-bold">
            <div class="col-6">{{"label.userID" | translate}}<span style="float: inline-end">:</span></div>
            <div class="col-6 text-center">
              {{editForm.get('userId')?.value}}
            </div>
            <div class="col-6">{{"label.lastAuditedUser" | translate}}<span style="float: inline-end">:</span></div>
            <div class="col-6 text-center">{{auditStatus.lastAuditedBy || '-'}}</div>
            <div class="col-6">{{"label.lastAuditedDate" | translate}}<span style="float: inline-end">:</span></div>
            <div class="col-6 text-center">{{auditStatus.lastAuditedDate ? (auditStatus.lastAuditedDate | date:
              'dd/MM/yyyy') : '-'}}</div>
          </div>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
  <div class="flex justify-content-center mt-5 gap-3"
    *ngIf="(((activeIndex === 0 && !disableInputs) || (activeIndex === 2 && emailTypes.length > 0)))">
    <p-button label="{{'editUserScreen.resetPassword' | translate}}"
      (click)="resetPassword()" styleClass="p-button-text"></p-button>
    <p-button icon="pi pi-check" [loading]="loading" label="{{'button.save' | translate}}" [disabled]="!editForm.valid"
      (click)="saveUser()" styleClass="p-button-text"></p-button>
      <p-button icon="pi pi-danger" (click)="closeRef(forceUpdate)" label="{{'button.close' | translate}}"
                styleClass="p-button-text"></p-button>
  </div>
</form>
<p-dialog header="Header" [(visible)]="enableDialog || loadingDialog " [modal]="true" [style]="{ width: '50vw' }" (onHide)="modelHide()" [closable]="false">
  <ng-template pTemplate="header">
      <span class="text-xl font-bold">{{"editUserScreen.resetPassword" | translate}}</span>
  </ng-template>
 <div class="card">
    <div class="flex gap-5 flex-column align-items-center justify-content-center" *ngIf="loadingDialog">
      <p> {{"message.createTempIdResponse" | translate}}</p>
		<p-progressSpinner strokeWidth="5" styleClass="w-5rem h-5rem"
						   ariaLabel="loadingDialog" animationDuration=".8s"/>
    </div>
    <div *ngIf="!responseMessage && !loadingDialog">
      <p class="text-center text-black-alpha-90 font-bold mb-4"> {{"editUserScreen.passwordResetFor" | translate}} {{createdUserId}}</p>
      <p class="text-center text-black-alpha-90 mb-4"> {{"editUserScreen.resetPasswordMessage" | translate}}</p>
    </div>
    <div class="flex justify-content-center" *ngIf="!responseMessage && !loadingDialog">
      <div class="col-4">
        <label for="alphabetic" class="font-bold block mb-2"> {{"label.userID" | translate}} </label>
        <div id="buttonGroup" class="p-inputgroup">
        <input type="text" pInputText pKeyFilter="alpha" [(ngModel)]="createdUserId" placeholder="{{'label.userID' | translate }}"/>
        <button type="button" pButton [cdkCopyToClipboard]="createdUserId" [icon]="copyUserId ? 'pi pi-check' : 'pi pi-copy'" (click)="copyUser()" styleClass="p-button-warn"></button>
        </div>
      </div>
      <div class="col-4">
        <label for="alphabetic" class="font-bold block mb-2"> {{"label.temporaryPassword" | translate}} </label>
        <!-- <input pInputText id="alphabetic" pKeyFilter="alpha" [(ngModel)]="tempPwd" class="w-full mb-2" />
        <p-button [cdkCopyToClipboard]="tempPwd" (click)="copy()" label="{{copyMessage}}"></p-button> -->
        <div id="buttonGroup" class="p-inputgroup">
          <input type="text" pInputText [(ngModel)]="tempPwd" placeholder="{{'label.temporaryPassword' | translate }}" />
          <button type="button" pButton [cdkCopyToClipboard]="tempPwd" [icon]="copyPass ? 'pi pi-check' : 'pi pi-copy'" (click)="copyPassword()" styleClass="p-button-warn"></button>
      </div>

      </div>
    </div>
    <div *ngIf="!responseMessage && !loadingDialog">
      <p class="text-center text-red-400 font-bold mb-4">{{"message.waitNewAccount" | translate}}
      </p>
      <p class="text-center text-red-400">{{"editUserScreen.noteMessage" | translate}}
      </p>
    </div>
    <p *ngIf="responseMessage && !loadingDialog">
      {{responseMessage}}
    </p>
 </div>
  <ng-template pTemplate="footer">
      <p-button icon="pi pi-danger" (click)="closeRef(true)" label="{{'button.close' | translate}}" styleClass="p-button-text"></p-button>
      <!-- <p-button icon="pi pi-danger" (click)="visible = false" label="No" styleClass="p-button-text"></p-button> -->
  </ng-template>
</p-dialog>
