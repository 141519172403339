<div [adminCheck]="PageType.MANAGE_AUDIT_CONFIG">
	<app-title [userTitle]="'title.groupAuditConfiguration' | translate"></app-title>
	<div class="border-round-xl card bg-color-2  shadow-3  m-2 py-3 pb-4" [class.col-10]="isCollapsed" [class.mx-auto]="isCollapsed">
		<div class="card flex gap-4 justify-content-center flex-column lg:flex-row align-items-center">
			<div class="lg:col-3 col-6 flex flex-column gap-2">
				<label class="font-bold">{{"label.branch" | translate}}</label>
				<p-dropdown styleClass="w-full" class="flex-1" [options]="branchList" [disabled]="isBranchLoading || isSiteCodeLoading || isMarketLoading" [dropdownIcon]="isBranchLoading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"  [(ngModel)]="selectedBranch" (onChange)="onChangeBranch()" placeholder="{{'placeholder.selectBranch' | translate}}" optionLabel="branchName" [filter]="true" [resetFilterOnHide]="true" filterBy="branchName" required></p-dropdown>
			</div>
			<div class="lg:col-3 col-6 flex flex-column gap-2">
				<label class="font-bold">{{"label.fordSitecodeType" | translate}}</label>
				<p-dropdown  styleClass="w-full" class="flex-1" [disabled]=" isSiteCodeLoading || isMarketLoading" [dropdownIcon]="isSiteCodeLoading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" [options]="siteCodeTypeList" [(ngModel)]="selectedSiteCodeType" placeholder="{{'placeholder.selectSiteCodeType' | translate}}" [resetFilterOnHide]="true"  (onChange)="onChangeSiteCodeType()" optionLabel="siteCodeType" [filter]="true" filterBy="siteCodeType" required></p-dropdown>
			</div>
			<div class="lg:col-3 col-6 flex flex-column gap-2">
				<label class="font-bold">{{"label.country/market" | translate}}</label>
				<p-dropdown styleClass="w-full" class="flex-1" [disabled]= "isMarketLoading" [dropdownIcon]="isMarketLoading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" [options]="marketList" [(ngModel)]="selectedMarket" (onChange)="getAuditConfigration()" placeholder="{{'placeholder.selectMarket' | translate}}" [resetFilterOnHide]="true" optionLabel="marketName" [filter]="true" filterBy="marketName" required ></p-dropdown>
			</div>
		</div>
	</div>
	<br>
<!--	<div class="flex justify-content-center gap-3" >-->
<!--		<p-button [disabled]="!selectedMarket || auditLoader" label="Extend Audit Date" (click)="showExtendAudit = true"></p-button>-->
<!--	</div>-->
	<div class="mt-7" *ngIf="initialSearch">
		<div class="text-center h-5rem" *ngIf="auditLoader else configLoader">
			<p-progressSpinner strokeWidth="5" styleClass="w-5rem h-5rem"
							   ariaLabel="{{'label.loading' | translate}}" animationDuration=".8s"/>
		</div>
		<ng-template #configLoader>
			<div *ngIf="auditConfiguration; else configEmpty;" class="text-end mr-3">
				<p-table id="audit-table" [value]="[auditConfiguration]" [tableStyle]="{ 'min-width': '50rem' }">
					<ng-template pTemplate="header">
						<tr>
							<th>{{"label.country" | translate }}</th>
							<th> {{"label.auditStartDate" | translate }}</th>
							<th> {{"label.auditDueDate" | translate }}</th>
							<th> {{"label.extendedDays" | translate }}</th>
							<th> {{"label.auditIntervalDays" | translate }}</th>
							<th> {{"label.auditCycleDays" | translate }}</th>
							<th> {{"label.notification" | translate }}</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-auditConfiguration>
						<tr>
							<td>{{ selectedMarket?.marketName}}</td>
							<td>{{ auditConfiguration?.auditStartDate | date:'dd-MMM-yyyy'}}</td>
							<td>{{ auditConfiguration?.auditDueDate | date:'dd-MMM-yyyy'}}</td>
							<td>{{auditConfiguration?.auditExtensionDays > 0 ? auditConfiguration?.auditExtensionDays : '-'}}</td>
							<td>{{auditConfiguration?.auditInitInterval}}</td>
							<td>{{auditConfiguration?.auditIntervalDay}}</td>
							<td class="text-center">
								<p-badge [value]="getBadgeValue(auditConfiguration)" styleClass="text-no-wrap"
										 [severity]="auditConfiguration.sendNotification === ConditionEnum.Yes ? 'success' : 'danger'">
								</p-badge>
							</td>

						</tr>
					</ng-template>
				</p-table>
				<div class="mt-6 flex justify-content-center gap-3" [adminCheck]="PageType.MANAGE_AUDIT_CONFIG" [buttonGroup]="true">
					<p-button label="{{'button.updateAudit' | translate}}" (click)="showConfigurationDialog('button.update',auditConfiguration)"></p-button>
					<p-button label=" {{'button.extendAudit' | translate}}" [disabled]="!pastAudit"  (click)="showExtendAudit = true"></p-button>
				</div>

			</div>
		</ng-template>
		<ng-template  #configEmpty>
			<div class="text-center">
				<h4 class="mb-4">{{"message.noConfigurationForCountry" | translate}}</h4>
				<p-button label="{{'button.createAudit' | translate}}" [outlined]="true" (click)="showConfigurationDialog()"><i class="pi pi-plus text-sm pr-2"> </i> </p-button>
			</div>
		</ng-template>
	</div>
</div>

<p-dialog header="{{'message.extendAuditDay' | translate}}" [style]="{ width: '34vw' }" [(visible)]="showExtendAudit" position="center" [resizable]="false" [closable]="false" [modal]="true" [draggable]="false">
	<div *ngIf="!showdataloader;">
		<form #auditForm="ngForm" (ngSubmit)="extendAuditDate(extendauditdays,auditForm)">
			<div class="col-12 text-center mt-3">
				<div class="col-10 mx-auto relative">
					<input autofocus pInputText name="extendauditdays" class="w-full" type="number"
					   [(ngModel)]="extendauditdays" #extendauditday="ngModel" [min]="1" [max]="30" required
						placeholder="{{'placeholder.enterDaysToExtend' | translate }}" />
					<div class="absolute" *ngIf="extendauditday.invalid && (extendauditday.dirty || extendauditday.touched)">
						<small class="text-red-400" *ngIf="extendauditday.errors?.required">{{"message.requiredField" | translate}}</small>
						<small class="text-red-400" *ngIf="extendauditday.errors?.min"> {{"message.valueAtLeastOne" | translate}}</small>
						<small class="text-red-400" *ngIf="extendauditday.errors?.max">{{"message.auditMaxDays" | translate}}</small>
					</div>
				</div>
				<div class="flex gap-2 mt-5 justify-content-center">
					<p-button type="submit" [disabled]="auditForm.invalid">{{"label.submit" | translate}}</p-button>
					<p-button (click)="showExtendAudit = false">{{"button.cancel" | translate}}</p-button>
				</div>
			</div>

		</form>
	</div>
	<div *ngIf="showdataloader" class="flex justify-content-center my-4">
		<p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="5" animationDuration=".7s"></p-progressSpinner>
	</div>
</p-dialog>

<p-dialog styleClass="custom-bg" header="{{configLabel | translate}} {{'header.configuration' | translate}} " [(visible)]="showConfigurationModel" [draggable]="false" [modal]="true" [style]="{minWidth: '40vw'}">
	<div *ngIf="showSubmitloader" class="flex justify-content-center my-8">
		<p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="5" animationDuration=".7s"></p-progressSpinner>
	</div>
	<div class="bg-white m-2 p-4 border-round-2xl shadow-2" *ngIf="!showSubmitloader">
		<div class="m-2 mt-0" *ngIf="auditRequest">
			<form [formGroup]="configureForm" (ngSubmit)="updateOrCreateConfig(auditRequest)">
				<div class="flex flex-column gap-2">
					<label class="required-label">{{"label.auditStartDate" | translate}} </label>
					<p-calendar formControlName="startDate" [showIcon]="true" [minDate]="minAuditDate" appendTo="body" styleClass="w-full"></p-calendar>
				</div>
				<br>
				<div class="flex gap-2">
					<div class="col-6 px-0 flex flex-column gap-2">
						<label class="required-label">{{"label.lengthAuditDays" | translate}}</label>
						<div class="relative">
							<input pInputText type="number" formControlName="auditInitInterval" aria-describedby="username-help" min="1" />
							<div class="absolute" *ngIf="configureForm.get('auditInitInterval').invalid && configureForm.get('auditInitInterval').touched
							&& configureForm.get('auditInterval').hasError('min')">
								<small class="p-error">{{"message.daysValueAtLeastOne" | translate}}</small>
							</div>
						</div>
					</div>
					<div class="col-6 px-0 flex flex-column gap-2">
						<label class="required-label">{{"label.daysAuditCycle" | translate}}</label>
						<div class="relative">
							<input pInputText type="number" formControlName="auditInterval" aria-describedby="username-help" min="1" />
							<div class="absolute" *ngIf="configureForm.get('auditInterval').invalid">
								<small class="p-error" *ngIf="configureForm.get('auditInterval').hasError('min')">
									{{"message.daysValueAtLeastOne" | translate}}
								</small>
								<small class="p-error" *ngIf="!configureForm.get('auditInterval').hasError('min') && configureForm.get('auditInterval').hasError('auditIntervalInvalid')">
									{{"message.greaterThanAuditLength" | translate}}
								</small>
							</div>
						</div>
					</div>
				</div>
				<div class="card flex p-2 mx-2 gap-4 justify-content-center flex-column lg:flex-row align-items-center">
					<div class="col-6 px-0 flex flex-column flex-wrap gap-3 align-items-center">
						<label class="required-label">{{"label.sentNotification" | translate}}</label>
						<div class="flex flex-wrap gap-3">
							<div class="flex align-items-center">
								<p-radioButton formControlName="sendNotification" [value]="ConditionEnum.Yes" [inputId]="ConditionEnum.Yes"></p-radioButton>
								<label class="ml-2 not-required">{{"label.yes" | translate}}</label>
							</div>
							<div class="flex align-items-center">
								<p-radioButton formControlName="sendNotification" [value]="ConditionEnum.No" [inputId]="ConditionEnum.No" (click)="onSentNotificationClick()"></p-radioButton>
								<label class="ml-2 not-required">{{"label.no" | translate}}</label>
							</div>
						</div>
					</div>
					<div class="col-6 px-0 flex flex-column gap-2">
						<label [class.required-label]="configureForm.get('sendNotification').value === ConditionEnum.Yes">{{"label.notificationfrequency" | translate}}</label>
						<div class="relative">
							<input pInputText type="number" formControlName="notificationFreq" min="1" />
							<div class="absolute" *ngIf="configureForm.get('notificationFreq').invalid &&
							configureForm.get('notificationFreq').touched ">
								<small *ngIf="configureForm.get('sendNotification').value === ConditionEnum.Yes &&
							configureForm.get('notificationFreq').hasError('min')" class="p-error">{{"message.daysValueAtLeastOne" | translate}}</small>
								<small *ngIf="!configureForm.get('notificationFreq').hasError('min') &&
								configureForm.get('notificationFreq').hasError('notificationFreqInvalid')" class="p-error">
									{{"message.lesserThanAuditLength" | translate}}
								</small>
							</div>
						</div>
					</div>
				</div>
				<div class="flex gap-4 mt-5 justify-content-center">
					<p-button type="submit" label="{{configLabel | translate}} {{'header.configuration' | translate}}"></p-button>
				</div>
			</form>
		</div>
	</div>
</p-dialog>

