<div *ngIf="!tableLoader;else showLoader" >
	<div class="flex gap-3 mt-3 mb-5 justify-content-end"  [adminCheck]="PageType.MANAGE_TEMP_IDS" [skipPageAppend]="true"  [baseUserGroupModel]="baseUserGroupModel" [buttonGroup]="true">
		<p-button label="{{'button.addAdmin' | translate}}" [disabled]="disablebtn" (click)="openAddUser($event)" />
		<p-button [icon]="loadingDelete ? 'pi pi-spinner pi-spin':'pi pi-trash'" pTooltip="{{'tooltip.deleteAdmin' | translate}}" tooltipPosition="bottom" [disabled]="selectedUser.length == 0 || disablebtn" (click)="confirmDialog($event)"  />
	</div>
	<p-accordion [activeIndex]="0">
		<p-accordionTab [header]=" ('header.administrator' | translate) + ' (' + adminUsers.length + ')' ">
			<div >
				<h4 *ngIf="adminUsers.length == 0; else membertable" class="text-center">
					{{"message.noAdministratorFound" | translate}} </h4>
				<ng-template #membertable>
					<admin-member-table [adminCheck]="PageType.MANAGE_TEMP_IDS"  [baseUserGroupModel]="baseUserGroupModel" [skipPageAppend]="true" [adminUsers]="adminUsers" (selectedUserEmitter)="onSelectedUser($event)" (deleteUserEmitter)="onDeleteUser($event)" />
				</ng-template>
			</div>
		</p-accordionTab>
	</p-accordion>
</div>
<ng-template #showLoader>
	<div class="text-center h-5rem m-5">
		<p-progressSpinner strokeWidth="5" styleClass="w-5rem h-5rem"
						   ariaLabel="{{'label.loading' | translate}}" animationDuration=".8s"/>
	</div>
</ng-template>


<p-overlayPanel #opadmin [style]="{ 'width':'28vw','padding':'10px','border-radius': '10px'}" class="shadow-2" styleClass="bg-color-2" [dismissable]="true" [showCloseIcon]="true">
	<div class="col-12">
		<div>
			<form class="form" #adminAddFrom="ngForm" (ngSubmit)="addAdminUser(userId,isChecked,adminAddFrom)">
				<div class="relative mb-4">
					<input type="text" autofocus pInputText placeholder="Enter userId" id="adminUserId" name="adminUserId" [(ngModel)]="userId" class="w-full" required />
					<div class="absolute">
						<small class="text-red-400" *ngIf="inlineAdminMsg">
							{{inlineAdminMsg}}
						</small>
					</div>
				</div>
				<div class="flex justify-content-between  align-items-center">
					<div class="flex gap-2 flex-column">
						<label>Group Admin</label>
						<p-inputSwitch styleClass="scale-down-2x" [(ngModel)]="isChecked"></p-inputSwitch>
					</div>
					<p-button label="Add User" type="submit"></p-button>
				</div>
			</form>
		</div>
		<div *ngIf="inlineLoader" class="card flex justify-content-center spinner-mask">
			<p-progressSpinner strokeWidth="5" styleClass="w-4rem h-4rem"
							   ariaLabel="{{'label.loading' | translate}}" animationDuration=".8s"/>
		</div>
	</div>
</p-overlayPanel>
