<div class="flex justify-content-center totalRecordsMessage"> <span *ngIf="jobRolesList" class="flex justify-content-center w-auto user-sec-label">{{totalRecordsFound}}</span></div>
<p-table #dt [value]="jobRolesList" [columns]="cols" [scrollable]="true" [paginator]="true" [rows]="EnvConstants.PAGINATION_LENGTH" [customSort]="true"
  (sortFunction)="onSorting($event)" (onFilter)="onFilter($event)" scrollHeight="63vh" sortField="firstName"
  sortMode="single" dataKey="firstName" rowGroupMode="subheader" groupRowsBy="status" styleClass="p-datatable-striped"
  [rowsPerPageOptions]="EnvConstants.PAGINATION_ROW" exportFilename= "{{filename()}}" [tableStyle]="{'min-width': '50rem', 'min-height': '20vh'}">
  <ng-template pTemplate="caption">
    <div class="exportStyle">
        <p-button
            icon="pi pi-external-link"
            label="{{'label.export' | translate}}"
            (onClick)="dt.exportCSV()" />
    </div>
</ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th class="tableheaderwidth" pSortableColumn="jobRoleCode"> {{"label.jobRoleCode" | translate}}<p-sortIcon field="jobRoleCode"></p-sortIcon>
        <br>
        <input *ngIf="showFilter" pInputText type="text" (click)="$event.stopPropagation()"
          (input)="dt.filter($event.target.value, 'jobRoleCode', 'contains')" class="p-column-filter inputbox"
          [value]="dt.filters['jobRoleCode']?.value">
      </th>
      <th class="tableheaderwidth" pSortableColumn="jobRoleName">{{"label.jobRoleName" | translate}}<p-sortIcon field="jobRoleName"></p-sortIcon>
        <br>
        <input *ngIf="showFilter" pInputText type="text" (click)="$event.stopPropagation()"
          (input)="dt.filter($event.target.value, 'jobRoleName', 'contains')" class="p-column-filter inputbox"
          [value]="dt.filters['jobRoleName']?.value">
      </th>
      <th class="tableheaderwidth" pSortableColumn="country">{{"label.market" | translate}}<p-sortIcon field="country"></p-sortIcon>
        <br>
        <input *ngIf="showFilter" pInputText type="text" (click)="$event.stopPropagation()"
          (input)="dt.filter($event.target.value, 'country', 'contains')" class="p-column-filter inputbox"
          [value]="dt.filters['country']?.value">
      </th>
      <th class="tableheaderwidth">{{"label.action" | translate}}</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-jobRole>
    <tr >
      <!-- <td>
        <p-tableCheckbox></p-tableCheckbox>
      </td> -->
      <!-- <td>
        <a>{{ jobRole.branchCode }}</a>
      </td>
      <td>
        {{ jobRole.siteCodeType }}
      </td> -->

      <td>
        {{ jobRole.jobRoleCode }}
      </td>
      <td>
        {{ jobRole.jobRoleName }}
      </td>
      <td>
        {{ jobRole.country }}
      </td>
      <td>
        <i class="pi pi-pencil cursor-pointer" (click)="onClickEdit(jobRole)"></i>&nbsp;&nbsp;
        <i class="pi pi-trash cursor-pointer" (click)="deleteJobRole(jobRole)"></i>
      </td>
    </tr>
  </ng-template>
</p-table>
<app-edit-job-role></app-edit-job-role>
<p-dialog header="Header" [(visible)]="visible" [modal]="true" >
  <ng-template pTemplate="header">
      <span class="text-xl font-bold">{{("label."+responseTitle) | translate}}</span>
  </ng-template>
  <p *ngIf="!loading">
      {{responseMessage}}
  </p>
  <div class="flex align-items-center justify-content-center" *ngIf="loading">
    <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
  </div>
  <ng-template  pTemplate="footer">
      <p-button *ngIf="responseTitle==='deleteConfirmation'" icon="pi pi-check" [loading]="loadingButton" (click)="confirmDelete()" label="{{'label.yes' | translate}}" styleClass="p-button-text"></p-button>
      <p-button *ngIf="responseTitle==='deleteConfirmation'" icon="pi pi-danger" (click)="visible = false" label="{{'label.no' | translate}}" styleClass="p-button-text"></p-button>
      <p-button *ngIf="responseTitle!=='deleteConfirmation'" icon="pi pi-danger" (click)="closeResponseDialog()" label="{{'button.close' | translate}}" styleClass="p-button-text"></p-button>
</ng-template>
</p-dialog>
