<div [adminCheck]="PageType.MEMBERS" [baseUserGroupModel]="baseUserGroupModel">
	<p-table #dt [value]="customers" [(selection)]="selectedAudits" (onRowSelect)="onRowSelect($event,title)" (onRowUnselect)="onRowUnselect($event,title)" [scrollable]="true" [paginator]="true" [rows]="EnvConstants.PAGINATION_LENGTH" [customSort]="true"
			 (sortFunction)="customSort($event)" (onFilter)="onFilter($event)" sortField="firstName" selectionMode="multiple"
			 (onHeaderCheckboxToggle)="onTableSelectAll($event,title)" sortMode="single" dataKey="userId" rowGroupMode="subheader" styleClass="p-datatable-striped"
			 [rowsPerPageOptions]="EnvConstants.PAGINATION_ROW" [tableStyle]="{ 'width': '100%' }">
		<ng-template pTemplate="header" class="table-members">
			<tr>
				<th class="theader-checkbox">
					<div class="text-center">
						<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
					</div>
				</th>
				<th class="theader" pSortableColumn="userId">
					<p class="m-0 mb-2">{{"label.userID" | translate}} <p-sortIcon field="userId"></p-sortIcon></p>
					<input *ngIf="showFilter" pInputText type="text" (click)="$event.stopPropagation()"

						   (input)="dt.filter($event.target.value, 'userId', 'contains')" class="p-column-filter"
						   [value]="dt.filters['userId']?.value">
				</th>

				<th class="theader" pSortableColumn="lastName">
					<p class="m-0 mb-2">{{"label.lastName" | translate}} <p-sortIcon field="lastName"></p-sortIcon></p>
					<input *ngIf="showFilter" pInputText type="text" (click)="$event.stopPropagation()"
						   (input)="dt.filter($event.target.value, 'lastName', 'startsWith')" class="p-column-filter"
						   [value]="dt.filters['lastName']?.value">
				</th>
				<th class="theader" pSortableColumn="firstName">
					<p class="m-0 mb-2">{{"label.firstName" | translate}}<p-sortIcon field="firstName"></p-sortIcon></p>
					<input *ngIf="showFilter" pInputText type="text" (click)="$event.stopPropagation()"

						   (input)="dt.filter($event.target.value, 'firstName', 'contains')" class="p-column-filter"
						   [value]="dt.filters['firstName']?.value">
				</th>
				<th style="width: 20%" pSortableColumn="passwordSetStatus">
					<p class="m-0 mb-2"> {{"label.passwordExpiration" | translate}}<p-sortIcon field="passwordSetStatus"></p-sortIcon></p>
					<input *ngIf="showFilter" pInputText type="text" (click)="$event.stopPropagation()"

						   (input)="dt.filter($event.target.value, 'passwordSetStatus', 'contains')" class="p-column-filter"
						   [value]="dt.filters['passwordSetStatus']?.value">
				</th>
				<th style="width: 15%" pSortableColumn="lastAuditedDate">
					<p class="m-0 mb-2">{{"label.audit" | translate}}<p-sortIcon field="lastAuditedDate"></p-sortIcon></p>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-customer>
			<tr [class.userid-hover-secUser]="customer.location != CommonConstants.PRIMARY">
				<td class="text-center">
					<p-tableCheckbox [value]="customer" ></p-tableCheckbox>
				</td>
				<td>
					<div class="flex align-items-center justify-content-center" *ngIf="!loadingArr[customer.userId];else loadingcustomer">
						<p-button [label]="customer.userId" [link]="true" styleClass="p-1" (click)="callEditUser(customer)" [disabled]="userLoading"/>
						<i *ngIf="customer.location != 'Primary'" class="pi pi-home mx-1" style="font-size: 1rem" pTooltip="{{customer.primaryLocation}}"></i>
					</div>
					<ng-template #loadingcustomer>
						<div class="flex justify-content-center">
							<i class="pi pi-spin pi-spinner text-xl" ></i>
						</div>
					</ng-template>
				</td>
				<td>
					{{ customer.lastName }}
				</td>
				<td>
					{{ customer.firstName }}
				</td>
				<td class="text-center">
					<p-badge [value]="getPasswordStatus(customer)" styleClass="text-no-wrap" [severity]="customer.passwordSetStatus === PasswordStatus.ACTIVE ? 'success': 'danger'" />
				</td>
				<td class="text-center audit-item">
					<p  *ngIf="customer.lastAuditedBy;else noaudit" class="inline-flex flex-column m-0">
						<span><strong>{{customer.lastAuditedBy | uppercase}} </strong></span>
						<span>({{customer.lastAuditedDate | date:'dd-MMM-yy'}})</span>
					</p>
					<ng-template #noaudit>
						<p class="m-0"> - </p>
					</ng-template>
				</td>
			</tr>
		</ng-template>
	</p-table>
</div>

