<p-table #dt [value]="members" [scrollable]="true" [paginator]="true" [rows]="EnvConstants.PAGINATION_LENGTH" [customSort]="true" [(selection)]="selectedIds"
	(onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" (onHeaderCheckboxToggle)="onTableSelectAll($event)"
	(sortFunction)="onSorting($event)" (onFilter)="onFilter($event)" scrollHeight="63vh" sortField="firstName" selectionMode="multiple"
	sortMode="single" dataKey="userId" rowGroupMode="subheader" groupRowsBy="status" styleClass="p-datatable-striped"
	[rowsPerPageOptions]="EnvConstants.PAGINATION_ROW" [tableStyle]="{ 'min-height': '20vh'}">
	<ng-template pTemplate="header">
		<tr>
			<th style="width: 4rem">
				<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
			</th>
			<th style="width: 20%" pSortableColumn="userId">{{"label.userID" | translate}}<p-sortIcon field="userId"></p-sortIcon>
				<br>
				<input *ngIf="showFilter" pInputText type="text" (click)="$event.stopPropagation()"
					style="height: 25px;margin-top: 2px; margin-bottom: 3px; width:100% !important;"
					(input)="dt.filter($event.target.value, 'userId', 'contains')" class="p-column-filter"
					[value]="dt.filters['userId']?.value">
			</th>

			<th style="width: 20%" pSortableColumn="lastName">{{"label.lastName" | translate}}<p-sortIcon field="lastName"></p-sortIcon>
				<br>
				<input *ngIf="showFilter" pInputText type="text" (click)="$event.stopPropagation()"
					style="height: 25px;margin-top: 2px; margin-bottom: 3px; width:100% !important;"
					(input)="dt.filter($event.target.value, 'lastName', 'startsWith')" class="p-column-filter"
					[value]="dt.filters['lastName']?.value">
			</th>
			<th style="width: 20%" pSortableColumn="firstName">{{"label.firstName" | translate}}<p-sortIcon field="firstName"></p-sortIcon>
				<br>
				<input *ngIf="showFilter" pInputText type="text" (click)="$event.stopPropagation()"
					style="height: 25px;margin-top: 2px; margin-bottom: 3px; width:100% !important;"
					(input)="dt.filter($event.target.value, 'firstName', 'contains')" class="p-column-filter"
					[value]="dt.filters['firstName']?.value">
				</th>
        <th style="width: 20%" pSortableColumn="audit">{{"label.primaryLocation" | translate}}<p-sortIcon field="audit"></p-sortIcon>
          <br>
          <input *ngIf="showFilter" pInputText type="text" (click)="$event.stopPropagation()"
            style="height: 25px;margin-top: 2px; margin-bottom: 3px; width:100% !important;"
            (input)="dt.filter($event.target.value, 'audit', 'contains')" class="p-column-filter"
            [value]="dt.filters['audit']?.value">
          </th>
			<th style="width: 20%" pSortableColumn="passwordSetStatus">{{"label.passwordStatus" | translate}}<p-sortIcon field="passwordSetStatus"></p-sortIcon>
				<br>
				<input *ngIf="showFilter" pInputText type="text" (click)="$event.stopPropagation()"
					style="height: 25px;margin-top: 2px; margin-bottom: 3px; width:100% !important;"
					(input)="dt.filter($event.target.value, 'passwordSetStatus', 'contains')" class="p-column-filter"
					[value]="dt.filters['passwordSetStatus']?.value">
				</th>
        <th class="tableheaderwidth">{{"label.action" | translate}}</th>
		</tr>
	</ng-template>

	<ng-template pTemplate="body" let-member>
		<tr>
			<td>
				<p-tableCheckbox [value]="member"></p-tableCheckbox>
			</td>
			<td>
				{{ member.userId }}
				<!-- <i *ngIf="member.primayLocation !== 'PIAUS0007' " class="pi pi-home" style="font-size: 1rem" pTooltip="{{member.primayLocation}}"></i> -->
			</td>
			<td>
				{{ member.lastName }}
			</td>
			<td>
				{{ member.firstName }}
			</td>

			<td>
				{{ member.primaryLocation }}
			</td>
      <td class="text-center">
				<p-badge [value]="getPasswordStatus(member)" styleClass="text-no-wrap" [severity]="member.passwordSetStatus === PasswordStatus.ACTIVE ? 'success': 'danger'" />
			</td>
      <td>
        <i class="pi pi-pencil cursor-pointer" (click)="editTempId(member)"></i>
      </td>
		</tr>
	</ng-template>
</p-table>
