import {AfterViewInit, Component, DestroyRef, inject, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router } from '@angular/router';
import {filter} from 'rxjs/operators';
import {SpinnerState} from "./common/models/spinner-state.model";
import {Message} from "primeng/api";
import {SpinnerService} from "./common/services/spinner.service";

/** Class for the app component that is bootstrapped to run the application
 */
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	/** string used to hold the url for the skipToMain link */
	skipToMain: string;
	isCollapsed = false;
	showSpinner=true;
	errorMessage: string;
	messages: Message[] | undefined;
	destroyRef = inject(DestroyRef);
	/** constructor for setting up DI in this component */
	constructor(private readonly router: Router,private readonly spinnerService:SpinnerService) {
	}



	/** this class requires this method to implement the OnInit interface */
	ngOnInit(): void {
		this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				this.setSkipLinkUrl(event.urlAfterRedirects);
			});
		const subscriber = this.spinnerService.spinnerObservable
			.subscribe((state: SpinnerState) => {
				console.log("subscribed");
			this.showSpinner = state.isLoading;
			this.errorMessage = state.errorMessage;
			if(this.errorMessage){
				const errors = this.errorMessage.length > 100 ? this.errorMessage.substring(0, 100) + '...' : this.errorMessage;
				this.messages = [{ severity: 'error', summary: 'API Error', detail: errors }]
			}
		});
		this.destroyRef.onDestroy(() => {
			subscriber.unsubscribe();
		});
	}

	onActivate(event : any) {
		this.spinnerService.setInitialLoadSpinner({isLoading:false});
	}


	/*this.userInfoService
.getUserInfo('j-lajsic')
.subscribe((response: any) => {
	console.log(response?.preferredLanguage);
	response.preferredLanguage = 'en';
	this.translate.use(response?.preferredLanguage || 'en');
});*/
	/**
	 * setSkipLinkUrl takes in a url string and processes whether
	 * the skipToMain link should be updated to use the new value
	 * @param currentUrl the new url to refer to
	 */
	private setSkipLinkUrl(currentUrl: string): void {
		if (!currentUrl.endsWith('#app-content')) {
			this.skipToMain = `${currentUrl}#app-content`;
		}
	}
}
