import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component, EventEmitter,
	Input,
	OnDestroy,
	OnInit, Output,
	ViewChild
} from '@angular/core';
import {UserGroupsService} from "../services/user-groups.service";
import {BehaviorSubject, finalize, Subject, take, takeUntil, tap} from "rxjs";
import {BaseUserGroupModel} from "../base-usergroup.model";

@Component({
  selector: 'audit-status',
  templateUrl: './audit-status.component.html',
  styleUrls: ['./audit-status.component.scss'],
	changeDetection:ChangeDetectionStrategy.OnPush
})
export class AuditStatusComponent implements OnInit,OnDestroy{

	@Input() auditData:BaseUserGroupModel;
	@Output() overallAudit = new EventEmitter<any>();
	private destroy$ = new Subject<void>();
	auditDate:string;
	auditStatus:string;
	lastAuditDate:string;
	lastAuditBy:string;
	totalCount:number=0;
	completeCount:number=0;
	showAuditLoader:boolean;
	isSiteCode:boolean;
	constructor(private userGroupService:UserGroupsService,private changeDetectorRef: ChangeDetectorRef,) {
		this.userGroupService.getAuditActionTrigger().subscribe((baseUser:BaseUserGroupModel)=>{
			if(baseUser) this.updateAuditStatus(baseUser);
		})
	}
	ngOnInit(): void {
		console.log('audit ngonit')
		// this.userGroupService.groupSelection$.pipe(takeUntil(this.destroy$)).subscribe(()=>{
		// 	console.log('subscribe called notified')
		// 	this.auditData && this.updateAuditStatus(this.auditData);
		// })
	}

	updateAuditStatus(auditData:BaseUserGroupModel){
		this.showAuditLoader = true;
		this.isSiteCode = !!auditData.siteCode
		this.resetData();
		this.changeDetectorRef.markForCheck();
		this.userGroupService.getDealerAuditStatus(auditData.branch,auditData.siteCodeType,auditData.market,auditData.siteCode)
			.pipe(finalize(()=>{this.showAuditLoader = false}),takeUntil(this.destroy$))
			.subscribe((data:any)=>{
				this.auditStatus = data.auditStatus ? data.auditStatus.toUpperCase(): null;
				this.auditDate = data.auditDueDate;
				this.totalCount = data.totalCount;
				this.completeCount = data.completedCount;
				this.lastAuditBy = data.lastAuditedBy ? data.lastAuditedBy.toUpperCase() : null;
				this.lastAuditDate = data.lastAuditedDate ? data.lastAuditedDate : null;
				this.changeDetectorRef.markForCheck();
				this.overallAudit.emit({auditStatus:this.auditStatus,auditDate:this.auditDate})
			},
		error => {this.showAuditLoader = false;this.changeDetectorRef.markForCheck()})
	}

	resetData(){
		this.auditDate = this.lastAuditDate = this.auditStatus = undefined;
		this.totalCount = this.completeCount = 0;
	}

	ngOnDestroy(): void {
		// this.notifier && this.notifier.unsubscribe();
		this.destroy$.complete();

	}

}
