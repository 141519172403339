import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {AuthService} from "./auth.service";

/** This class is a service to handle holding and distributing user ID to be displayed
 */
@Injectable({
	providedIn: 'root'
})
export class UserIdService {
	/** The User ID to be stored from authentication and distributed to other components in the app */
	private userId: BehaviorSubject<string> = new BehaviorSubject<string>(
		'Not Logged In'
	);

	private userName: Subject<string> = new Subject<string>();

	/** creates a new instance of the UserIdService
	 */
	constructor(@Inject(PLATFORM_ID) private platFormId:Object) {
		if(this.platFormId == 'browser') {
			const userId = sessionStorage.getItem('userId');
			if (userId) {
				this.setUserId(userId);
			}
		}
	}

	/** Stores user id
	 *
	 * @param userId the user ID to be stored on in the userId property of this service
	 */
	public setUserId(userId: string) {
		this.userId.next(userId);
	}

	/** gets the user ID stored in this service
	 *
	 * @returns the userId cast as an observable to be subscribed to by other components
	 */
	public getUserId(): Observable<string> {
		return this.userId;
	}

	public setUserName(userName: string) {
		this.userName.next(userName);
	}

	public getUserName():Observable<string>{
		return this.userName;
	}
}
