<router-outlet #outlet (activate)="onActivate($event)" ></router-outlet>
<p-toast position="top-center" styleClass="toast-msg" />
<div *ngIf="showSpinner || errorMessage" class="flex justify-content-center align-items-center h-screen flex-column gap-3">
	<ng-container *ngIf="showSpinner">
		<p-progressSpinner  styleClass="w-7rem h-7rem" strokeWidth="6" animationDuration=".5s"></p-progressSpinner>
	</ng-container>
	<ng-container *ngIf="errorMessage">
		<h3>Something went wrong. Please try again later</h3>
		<i class="far fa-sad-tear fa-8x mt-2"></i>
		<div class="mt-3">
			<p-messages [(value)]="messages" [enableService]="false" [closable]="false"></p-messages>
		</div>
	</ng-container>
</div>
