<div class="example-page" [ngClass]="{shifted: isCollapsed}">
	<app-title></app-title>
	<div class="card manage-user-card border-round-xl flex align-items-center
	justify-content-between p-2 m-2 mb-3 bg-color-2  shadow-3"
		 [adminCheck]="PageType.TREE_STRUCTURE" [excludeChange]="true">
		<div class="flex gap-3 ml-3">
			<search-tree-node (expandSelectEmitter)="updateSelection($event)" [selectedNodes]="selectedNodes"
			  	(nodeSelectEmitter)="getLabel($event)"></search-tree-node>
			<p-button *ngIf="baseUserGroupModel?.siteCode" styleClass="exclude-custom refresh-btn" icon="pi pi-refresh" severity="info" pTooltip="{{'tooltip.refreshMembers' | translate}}" (click)="getUsersForSiteCodes(baseUserGroupModel)"></p-button>
		</div>
		<div class="flex flex-column mr-7 fadein" *ngIf="selectedNodes">
			<div class="flex align-items-center">
				<p><strong>{{"label.name" | translate}}</strong>:</p>
				<span class="card flex justify-content-center" *ngIf="displayNodes?.length">
				<p-breadcrumb class="max-w-full" [model]="displayNodes"
							  styleClass="bg-none border-none" (onItemClick)="breadCrumbSelect($event)">
					<ng-template pTemplate="item" let-item>
						<p-button [label]="item?.label" [link]="true" styleClass="p-0 {{item?.label == selectedNodes?.label ? 'font-bold':''}}">
						</p-button>
					</ng-template>
					<ng-template pTemplate="separator"> - </ng-template>
				</p-breadcrumb>
				<button type="button" class="text-xs" pTooltip="Copy" pButton [cdkCopyToClipboard]="groupName" icon="pi pi-copy" (click)="copyGroup()" styleClass="p-button-warn"></button>
			</span>
			</div>
			<p><strong>{{"label.description" | translate}}</strong>: {{siteCodeDescription}}</p>
		</div>
	</div>
	<div *ngIf="selectedNodes && displayNodes && displayNodes.length > 1">
		<div class="flex align-items-center">
			<div class="flex-1">
				<p-messages *ngIf="overallAuditStatus == 'INPROGRESS' || overallAuditStatus == 'OVERDUE'" [severity]="overallAuditStatus == 'INPROGRESS' ? 'success':'error'" styleClass="flex justify-content-center" >
					<ng-template pTemplate>
						<div class="flex gap-3 align-items-center">
							<i class="pi pi-exclamation-circle text-xl"></i>
							<h4 class="m-0">{{"message.groupAudit" | translate}} {{overallAuditStatus}}. {{"message.auditDueDate" | translate}} <span
								class="font-bold">{{overallAuditDate | date:'dd-MMM-yy'}}</span></h4>
						</div>
					</ng-template>
				</p-messages>
			</div>
			<div *ngIf="isSiteCode && activeItem==0">
				<div class="font-bold flex justify-content-end mb-2 mr-1">
					<span class="text-color-2 flex gap-2 justify-content-center user-sec-label">
						<i class="pi pi-home"></i>{{"label.secondaryUser" | translate}}</span>
				</div>
			</div>
    </div>
		<div>
      <app-transfer-user [selectedBranch]="displayNodes[0]?.label" [selectedSiteCodeType]="displayNodes[1]?.label" [selectedMarket]="displayNodes[2]?.label" [sourceSiteCode]="displayNodes[3]?.label" [baseUserGroupModel]="baseUserGroupModel" [siteCodes]="siteCodes" [selectedMembersForAction]="selectedMembersForAction"></app-transfer-user>
			<div id="tabmenu" class="card actions-container">
				<div class="flex justify-content-end gap-2 mb-2 mt-2" *ngIf="isSiteCode && activeItem==0"  [adminCheck]="PageType.MEMBERS" [baseUserGroupModel]="baseUserGroupModel"[buttonGroup]="true" >
					<p-button label="{{'button.createNewUser' | translate}}" icon="pi pi-user" [loading]="loadingButton" (click)="createUser()" [hidden]="disableCreateUser"/>
					<p-button label="{{'button.addSecondaryUser' | translate}}"  (click)="op.toggle($event)" icon="pi pi-user-plus" [hidden]="disableSecondaryUser"></p-button>
					<p-splitButton appendTo="body" class="mr-1" label="{{'button.actions' | translate}}" icon="pi pi-spin pi-cog"
								   [model]="actions" [disabled]="!selectedMembersForAction?.length" ></p-splitButton>
				</div>
				<div>
					<p-tabView activeIndex="0" *ngIf="tabs.length > 0" #manageTab (onChange)="onTabChange($event,manageTab)">
						<p-tabPanel *ngFor="let tab of tabs" [header]="tab.ttitle | translate">
							<ng-container *ngIf="tab.title == 'Members'">
								<ng-container *ngIf="memberLoader;else memberdata">
									<div class="text-center h-5rem m-5">
										<p-progressSpinner strokeWidth="5" styleClass="w-5rem h-5rem"
														   ariaLabel="{{'label.loading' | translate}}" animationDuration=".8s"/>
									</div>
								</ng-container>
								<ng-template #memberdata >
									<div [adminCheck]="PageType.MEMBERS" [baseUserGroupModel]="baseUserGroupModel" [excludeLinkChange]="true">
										<p-accordion [activeIndex]="0" *ngFor="let c of customerMember; let i = index">
											<p-accordionTab [header]="(c.ttitle | translate) + ' (' + (c.dataList.length || 0) + ')'">
												<h4 *ngIf="c.dataList.length == 0; else membertable" class="text-center">
													{{"label.no" | translate}} {{ c.title }} {{"message.foundSelectedSubGroup" | translate}}  </h4>
												<ng-template #membertable>
													<members-table [baseUserGroupModel]="baseUserGroupModel" [siteCode]="selectedNodes.label" [title]="c.title" [dataList]="c.dataList" (editUser)="editUser($event)" (removeUser)="removeUser($event)" [overallAuditStatus]="overallAuditStatus">
													</members-table>
												</ng-template>
											</p-accordionTab>
										</p-accordion>
									</div>
								</ng-template>
								<!--						  <ng-template #nomember><h4 class="text-center">Please select more specific group to view members.</h4></ng-template>-->
							</ng-container>
							<ng-container *ngIf="tab.title == 'Admin'">
								<div [adminCheck]="PageType.ADMINISTRATORS" [baseUserGroupModel]="baseUserGroupModel">
									<admin-user-groups  [baseUserGroupModel]="baseUserGroupModel"/>
								</div>
							</ng-container>
							<ng-container *ngIf="tab.title == 'Audit'">
								<div [adminCheck]="PageType.AUDIT" [baseUserGroupModel]="baseUserGroupModel">
								<audit-status [auditData]="baseUserGroupModel" (overallAudit)="getOverallAudit($event)"></audit-status>
								</div>
							</ng-container>
							<ng-container *ngIf="tab.title == 'Support Center'">
								<div [adminCheck]="PageType.SUPPORT_CENTER" [baseUserGroupModel]="baseUserGroupModel">
								<support-center></support-center>
								</div>
							</ng-container>
						</p-tabPanel>
					</p-tabView>

				</div>
			</div>
			<p-dialog header="Header" [(visible)]="enableDialog" [modal]="true" [style]="{ width: '50vw'}">
				<ng-template pTemplate="header">
					<span class="text-xl font-bold">{{ responseModalTitle | translate}}</span>
				</ng-template>
				<p>
					{{ responseMessage }}
				</p>
				<ng-template pTemplate="footer">
					<p-button icon="pi pi-danger" (click)="enableDialog = false" label="{{'button.close' | translate}}"
							  styleClass="p-button-text"></p-button>
					<!-- <p-button icon="pi pi-danger" (click)="visible = false" label="No" styleClass="p-button-text"></p-button> -->
				</ng-template>
			</p-dialog>
      <p-overlayPanel #op [style]="{'min-width':'450px','padding':'30px'}" class="shadow-8" styleClass="bg-color-2" (onHide)="clearInputData()" [dismissable]="true">

        <div>
         <div class="card flex justify-content-center align-items-end mb-2">
          <div class="col-10">
            <div class="flex justify-content-start"><label class="font-bold block mb-2"> {{"label.userID" | translate}}  </label><span class="text-red-500"> *</span></div>
            <input autofocus type="text" pInputText [(ngModel)]="userIdSecondary" (ngModelChange)="onChangeSecInput()" (keydown.enter)="addSecondaryUser()" pKeyFilter="alpha"  class="w-full" maxlength="8"/>
						<span *ngIf="userIdSecondaryError" class="text-red-500 text-xs"> {{userIdSecondaryError}} </span>
          </div>
        </div>
        <div class="flex justify-content-center gap-3">
          <p-button icon="pi pi-plus" label="{{'button.add' | translate}}" (click)="addSecondaryUser()" [disabled]="!userIdSecondary" styleClass="p-button-text"></p-button>
          <p-button icon="pi pi-danger" label="{{'button.close' | translate}}" (click)="closeOP()" styleClass="p-button-text"></p-button>
        </div>
        <p *ngIf="showSecondaryUserError()" class="message-overlay {{respMessageClass}} text-center font-bold">{{secondaryUserResponse}}</p>
          <div *ngIf="secondaryLoader" class="card flex justify-content-center spinner-mask">
            <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
        </div>
          <!-- <p-button icon="pi pi-times" (click)="" label="Cancel" styleClass="p-button-text"></p-button> -->
        </div>

    </p-overlayPanel>
		</div>
	</div>
</div>
